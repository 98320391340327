$primary: #2f49d1;
$secondary: #f0f2fa;
$white: #ffffff;
$blood: #b80304;
$oxygen: #0679f8;
$stockbank: #006400;
$dew: #f7f7f7;
$neutral: #eeeeee;
$dark: #172b4d;
$light: #d7dfe9;
$error: #ff0000;
$grey: #b6b6b6;
$darkGrey: #888787;
$gold: #ffd700;
$bronze: #cd7f32;
$silver: #c0c0c0;
$iris: #a5a6f6;
$success: #4bde97;
$new: #424200;
$assign: #424200;
$prospect: #767601;
$pitch: #441ab7;
$onboard: #424200;
$exodus: #663300;
$active: #114b29;
$inactive: #b71c1c;
$yellow: #fae20c;
$hibernation: #4d8c5e;
$font-weights: (
  "regular": 400,
  "medium": 600,
  "bold": 900,
);
