@import "./config";
@import "./mixins";
@import "./utilities";
@import "./input";
@import "./button";

@media screen and (max-width: 68rem) {
  .login {
    &-inner {
      width: 80% !important;
      max-width: 20rem;

      &-title {
        font-size: 1.5rem !important;
        line-height: 1.5rem !important;
      }
    }
  }
}
