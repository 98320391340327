@mixin flexCenter($direction, $justify, $align: center) {
  display: flex;
  align-items: $align;
  flex-direction: $direction;
  justify-content: $justify;
}

@mixin transition($params) {
  -o-transition: $params;
  -moz-transition: $params;
  -webkit-transition: $params;
  transition: $params;
}

@mixin transform($direction) {
  transform: $direction;
  -webkit-transform: $direction;
  -moz-transform: $direction;
  -o-transform: $direction;
}

@mixin animation($params) {
  animation: $params;
  -webkit-animation: $params;
  -moz-animation: $params;
  -o-animation: $params;
}

@mixin shadow($color) {
  -webkit-box-shadow: 0px 1px 10px $color; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 1px 10px $color; /* Firefox 3.10 - 3.6 */
  box-shadow: 0px 1px 10px $color;
}
