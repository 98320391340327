@import "./config";
@import "./mixins";
@import "./utilities";

.ohw-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @include flexCenter(row, center);

  &-inner {
    width: auto;
    max-width: 35rem;
    min-width: 28rem;
    height: auto;
    max-height: 80vh;
    background-color: $white;
    border-radius: 0.187rem;
    padding: 1rem;
    position: relative;
    color: $dark;

    @media screen and (max-width: 48rem) {
      min-width: 80%;
      max-width: 80%;
    }

    button.close {
      width: 2rem;
      height: 2rem;
      background-color: $primary;
      color: $white;
      border: none;
      border-radius: 1rem;
      position: absolute;
      right: -1rem;
      top: -1rem;
      @include flexCenter(row, center);
    }

    &-body {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      max-height: 70vh;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .modal-title {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: get-weight(bold);
      text-align: left;

      &.confirm-validate-title {
        margin-top: 1rem;
        text-align: center;
      }

      span {
        color: $error;
      }
    }

    .confirm-validate-icon {
      font-size: 3rem;
      color: $yellow;
      text-align: center;
    }

    .confirm-validate-text {
      text-align: center;
    }

    .confirm-validate-text,
    .modal-body-text {
      font-size: 1.1rem;
    }

    .modal-body-text {
      text-align: left;
      margin: 1rem 0;
      font-weight: get-weight(regular);
    }

    .complaint-modal-items {
      margin: 1rem 0;

      &.no-list {
        padding: 0;
        list-style-type: none;
      }

      &-single {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $dark;
        text-align: left;
        margin: 1rem 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid lighten($grey, 20%);

        span {
          margin: 0 0.5rem;
          font-weight: get-weight(bold);

          &.update {
            color: $hibernation;
          }
        }

        i.fa {
          color: $error;
        }
      }
    }

    .modal-center-buttons {
      @include flexCenter(row, center);
    }

    .modal-buttons {
      @include flexCenter(row, flex-end);
    }

    .modal-buttons,
    .modal-center-buttons {
      .close-btn {
        margin-left: 1rem;
        width: auto;
      }
    }

    .chart-modal-inner {
      padding: 1rem;

      .chart-figure {
        font-size: 1.125rem;
        font-weight: get-weight(medium);
        color: $dark;
        line-height: 1.375rem;
        text-align: left;

        span {
          &.loss {
            color: $error;
          }

          &.profit {
            color: $success;
          }
        }
      }

      .ohw-checkbox-container {
        margin-left: 2rem;
      }

      .general-stats {
        height: auto;
        min-height: 15rem;
      }
    }

    .gamification {
      @include flexCenter(row, center);
      width: 100%;
      min-height: 20rem;
      height: 20rem;

      &-products {
        margin-right: 1rem;
        height: 100%;
        @include flexCenter(column, center);

        &-item {
          @include flexCenter(row, center);

          width: 10rem;
          flex: 1;
          margin: 0.1rem 0;
          background-color: $error;
          font-size: 0.875rem;
          color: $white;
          font-weight: get-weight(medium);

          &.active {
            opacity: 1;
          }

          &.disabled {
            opacity: 0.2;
          }
        }
      }

      &-progress {
        height: 100%;
        width: auto;

        @include transform(rotate(180deg));

        &-loader {
          width: 1rem;
          height: 100%;
          overflow: hidden;
          background-color: lighten($grey, 25%);
          border-radius: 2rem;

          @include shadow(lighten($grey, 25%));
          &-inner {
            width: 100%;
            @include transition(height 1s ease);
          }
        }
      }
    }
  }
}
