@import "config";
@import "mixins";
@import "utilities";
@import "animations";
@import "input";
@import "button";
@import "modal";
@import "progress";
@import "responsive";
@import "arrowBoxes";
@import "selectcountry";
@import "table";

.dashboard {
  width: 100%;
  height: 100vh;

  @include flexCenter(row, flex-start);

  .sb-pagination-container {
    @include flexCenter(row, flex-end);
    margin-top: 1.5rem;

    #ohw-pagination {
      @include flexCenter(row, flex-start);
      list-style: none;
      padding: 0;

      .ohw-page-item {
        .ohw-page-link {
          border: none;
          background-color: $white;
          width: 2rem;
          height: 2rem;
          border-radius: 0.4rem;
          color: $primary;
          margin-right: 0.5rem;

          i.fa {
            font-size: 0.75rem;
          }
        }

        &.active-page {
          .ohw-page-link {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }

  &-top {
    display: none;

    @media screen and (max-width: 48rem) {
      display: block;
    }

    nav {
      position: sticky;
      top: 0;
      z-index: 100;
      display: block;
      width: 100%;
      height: 3rem;
      min-height: 0;
      min-width: 100%;
      padding: 0 1rem;
      background-color: $primary;
      font-size: 0.82rem;
      @include flexCenter(row, space-between);

      img.lb-logo-only {
        height: 1.5rem;
        width: auto;
        object-fit: contain;
      }

      .responsive-toggle {
        cursor: pointer;

        .burger-line-top,
        .burger-line-middle,
        .burger-line-bottom {
          width: 1.5rem;
          height: 2px;
          margin: 0.3125rem 0;
          background-color: $white;
        }

        .burger-line-middle {
          width: 1.01rem;
        }
      }
    }

    .mobile-menu {
      width: 20rem;
      position: fixed;
      max-height: 100vh;
      height: 100%;
      overflow-y: auto;
      right: 0;
      top: 0;
      background-color: $primary;
      z-index: 100;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      @include animation(expand-menu-animation 0.5s);
      @include flexCenter(column, space-between);

      button.menu-toggle {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        background-color: transparent;
        border: none;
        color: $white;
        font-size: 1.25rem;
        z-index: 20;
      }

      ul.nav-items,
      ul.sublist {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li.nav-item {
          width: 100%;
          height: 2rem;
          margin: 1rem 0;
          color: $secondary;
          opacity: 0.6;
          font-size: 0.95rem;
          font-weight: get-weight(medium);
          cursor: pointer;

          @include transition(opacity 0.5s ease);

          &.active,
          &:hover {
            color: $white;
            opacity: 1;
            border-right: 4px solid $white;
          }

          @include flexCenter(row, flex-start);

          i.fa {
            width: 2rem;
            margin-left: 1rem;

            &-chevron-down {
              @include flexCenter(row, center);

              background-color: $secondary;
              width: 1.3rem;
              height: 1.3rem;
              color: $primary;
              border-radius: 1.5rem;
              margin: 0 0.5rem;
              font-size: 0.75rem;
            }
          }

          span {
            flex: 1;
            text-align: left;
          }

          div.complaints {
            @include flexCenter(row, center);

            background-color: $error;
            color: $white;
            width: 1.3rem;
            height: 1.3rem;
            font-size: 0.75rem;
            border-radius: 1.5rem;
            margin: 0 0.5rem;
          }
        }

        .hospitals {
          list-style-type: none;
        }
      }
    }
  }

  &-left {
    width: 15.8rem;
    max-height: 100vh;
    height: 100%;
    overflow-y: auto;
    background-color: $primary;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 48rem) {
      display: none;
    }

    @include transition(all 0.5s ease);
    @include flexCenter(column, space-between, flex-start);

    &.closed {
      width: 4.5rem;

      img.lb-logo {
        display: none;
      }

      ul.nav-items {
        li.nav-item {
          @include flexCenter(row, center);
          text-align: center;

          span {
            display: none;
          }

          i.fa {
            font-size: 1.3rem;
            margin-left: 0;

            &-chevron-down,
            &-chevron-up {
              display: none;
            }
          }

          div.complaints {
            display: none;
          }
        }
      }

      button {
        width: 4.5rem;
        @include flexCenter(row, center);

        span {
          display: none;
        }

        i.fa {
          font-size: 1.5rem;
        }
      }
    }

    &-top {
      width: 100%;

      &-header {
        height: 3.75rem;
        padding: 0.5rem;
        opacity: 0.6;

        @include flexCenter(row, space-between);

        img.lb-logo,
        img.lb-logo-only {
          height: 2rem;
          width: 6.5rem;
          object-fit: contain;
        }

        img.lb-logo-only {
          display: none;
          width: auto;
          margin-left: 1rem;
        }
      }
      ul.nav-items,
      ul.sublist {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li.nav-item {
          width: 100%;
          height: 2rem;
          margin: 1rem 0;
          color: $secondary;
          opacity: 0.6;
          font-size: 0.95rem;
          font-weight: get-weight(medium);
          cursor: pointer;

          @include transition(opacity 0.5s ease);

          &.active,
          &:hover {
            color: $white;
            opacity: 1;
            border-right: 4px solid $white;
          }

          @include flexCenter(row, flex-start);

          i.fa {
            width: 2rem;
            margin-left: 1rem;

            &-chevron-down,
            &-chevron-up {
              @include flexCenter(row, center);

              background-color: $secondary;
              width: 1.3rem;
              height: 1.3rem;
              color: $primary;
              border-radius: 1.5rem;
              margin: 0 0.5rem;
              font-size: 0.75rem;
            }
          }

          span {
            flex: 1;
            text-align: left;
          }

          div.complaints {
            @include flexCenter(row, center);

            background-color: $error;
            color: $white;
            width: 1.3rem;
            height: 1.3rem;
            font-size: 0.75rem;
            border-radius: 1.5rem;
            margin: 0 0.5rem;
          }
        }

        .hospitals {
          list-style-type: none;
        }
      }

      button.menu-toggle {
        background-color: transparent;
        border: none;
        color: $white;

        i.fa {
          font-size: 1.5rem;
        }
      }
    }

    &-bottom {
      width: 100%;
      // height: 2.5rem;
      margin-bottom: 1rem;

      button.sign-out {
        display: block;
        margin: 1rem 0;
        background-color: transparent;
        border: none;
        color: $secondary;
        opacity: 0.6;
        font-size: 0.95rem;
        font-weight: get-weight(medium);

        i.fa {
          width: 2rem;
        }
      }
    }
  }

  &-right {
    width: 100%;
    flex: 3;
    // background-color: $secondary;
    height: 100%;
    max-height: 100vh;
    padding: 1rem;
    margin-left: 4.5rem;

    @media screen and (max-width: 48rem) {
      margin-left: 0;
    }

    &-body {
      width: 100%;
      height: auto;

      header {
        height: 5rem;
        @include flexCenter(row, space-between);

        h1 {
          text-align: left;
          color: $dark;
          font-size: 1.5rem;
          line-height: 1.5rem;
          font-weight: get-weight(medium);
        }

        .header-right-options {
          @include flexCenter(row, center);

          button.toggle-city {
            height: auto;
            width: auto;
            padding: 0;
            background-color: transparent;
            border: none;
            border-radius: 0.175rem;
            color: $dark;
            font-weight: get-weight(bold);
            font-size: 0.875rem;
            text-transform: uppercase;

            &.first-item {
              margin-right: 1rem;
            }

            i.fa {
              margin-right: 0.3rem;
            }
          }
        }

        @media screen and (max-width: 48rem) {
          h1 {
            font-size: 1.1rem;
            line-height: 1rem;
            margin: 0;
          }
        }
      }

      .breakdown-item {
        background-color: $white;
        height: 8.56rem;
        min-width: 16.88rem;
        border-radius: 0.5rem;
        margin-right: 1rem;
        flex: 1;
        padding: 1rem;

        @include transition(box-shadow 0.5s ease);

        &:hover {
          @include shadow(lighten($grey, 15%));
        }

        h2 {
          margin: 0 0 0.5rem 0;
          color: $grey;
          line-height: 1rem;
          font-size: 1.125rem;
          text-align: left;
        }

        &-inner {
          @include flexCenter(row, flex-start);

          &-left {
            @include flexCenter(row, center);

            width: 3.75rem;
            height: 3.75rem;
            background-color: lighten($grey, 15%);
            border-radius: 50%;
            margin-right: 0.5rem;

            i.fa {
              font-weight: get-weight(medium);
            }
          }

          &-right {
            text-align: left;

            h3.title {
              font-weight: get-weight(medium);
              font-size: 1.125rem;
              line-height: 1.31rem;
              margin: 0;
            }

            p,
            .description {
              margin: 0.2rem 0;
              color: $grey;
              line-height: 1rem;
              font-size: 0.875rem;
            }

            .bottom {
              @include flexCenter(row, flex-start);

              .rate {
                &.increase {
                  color: $success;
                }

                &.decrease {
                  color: $error;
                }

                i.fa {
                  margin-right: 0.2rem;
                }
              }
              .description {
                margin-left: 0.2rem;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .mom-button-container {
          width: 100%;
          position: relative;

          button.mom {
            position: absolute;
            right: 0;

            color: $iris;
            font-weight: get-weight(bold);
            text-decoration: underline;
            background-color: transparent;
            display: inline-block;
            border: none;
            width: auto;
          }
        }
      }

      .top-row-breakdown {
        margin-bottom: 1rem;
        height: auto;

        button.slick-prev,
        button.slick-next {
          &:before {
            color: $primary;
          }
        }

        .monthly-unfulfilled,
        .monthly-active-hospitals,
        .monthly-onboarded-hospitals {
          margin-left: 1rem;
        }

        @media screen and (max-width: 64rem) {
          margin-bottom: 2rem;

          .monthly-total-hospitals {
            margin-left: 1rem;
          }
        }

        @media screen and (max-width: 48rem) {
          .monthly-onboarded-hospitals,
          .monthly-total-hospitals {
            margin-left: 0;
          }
        }

        @media screen and (max-width: 34.5em) {
          .monthly-unfulfilled,
          .monthly-active-hospitals,
          .monthly-onboarded-hospitals {
            margin-left: 0;
          }
        }

        .monthly-active-hospitals {
          .breakdown-item-inner-left {
            background-color: lighten($primary, 40%);
            color: $primary;
          }
        }

        .monthly-onboarded-hospitals {
          .breakdown-item-inner-left {
            background-color: lighten($success, 30%);
            color: $success;
          }
        }

        .monthly-unfulfilled {
          .breakdown-item-inner-left {
            background-color: lighten($error, 40%);
            color: $error;
          }
        }
      }

      .chart-breakdown {
        @include flexCenter(row, flex-start, flex-start);
        height: 23.13rem;

        &-left {
          min-width: 53rem;
          flex: 3;
          height: 100%;
          margin-right: 1rem;
          @include flexCenter(row, flex-start, flex-start);

          .assigned-hospital {
            &-slider {
              width: 100%;
              height: 100%;

              .slick {
                &-list {
                  max-height: 100%;
                }
                &-slide {
                  background-color: $white;
                  border-radius: 0.5rem;
                  margin-right: 1rem;
                  width: 100%;
                  height: 100%;
                  overflow-y: auto;
                  max-height: 23rem;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }

            width: 100%;
            height: 100%;
            padding: 1rem;
            @include transition(box-shadow 0.5s ease);

            &:hover {
              @include shadow(lighten($grey, 15%));
            }

            &-top {
              padding: 0 0 1rem 0;
              @include flexCenter(row, flex-start);

              &-initials {
                width: 5.6rem;
                height: 5.6rem;
                border-radius: 2.8rem;
                color: $white;
                background-color: $dark;
                font-size: 2.5rem;

                @include flexCenter(row, center);
              }

              &-details {
                flex: 1;
                margin-left: 1rem;

                .hospital {
                  &-status {
                    background-color: $pitch;
                    color: $white;
                    display: inline-block;
                    line-height: 1rem;
                    font-size: 0.65rem;
                    text-transform: uppercase;
                    font-weight: get-weight(regular);
                    padding: 0.25rem 0.5rem;
                    border-radius: 0.25rem;
                    margin-top: 0.25rem;
                  }

                  &-name {
                    font-size: 1rem;
                    font-weight: get-weight(bold);
                    line-height: 1.5rem;
                    text-transform: capitalize;
                    text-overflow: ellipsis;
                  }

                  &-email {
                    font-size: 0.75rem;
                    font-weight: get-weight(medium);
                    line-height: 1rem;
                    color: $darkGrey;
                  }
                }
              }
            }

            &-bottom {
              height: 100%;

              .details-item {
                margin-bottom: 1rem;
                @include flexCenter(row, center);

                &-icon {
                  width: 2.5rem;
                  height: 2.5rem;
                  margin-right: 1rem;
                  background-color: $secondary;
                  border-radius: 1.25rem;
                  @include flexCenter(row, center);
                }

                &-value {
                  flex: 1;
                  line-height: 1.25rem;
                  font-size: 0.875rem;
                  font-weight: get-weight(regular);
                  padding: 1rem 0;
                  border-top: 1px solid $light;
                  border-bottom: 1px solid $light;
                }
              }
            }
          }

          .sales-chart {
            background-color: $white;
            padding: 1rem;
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;

            h2 {
              text-align: left;
              line-height: 1.5rem;
              font-size: 1.2rem;
              color: $dark;
              font-weight: get-weight(medium);
            }

            .sales-statistics {
              height: auto;
              min-height: 15rem;
              max-height: 17.13rem;

              .chart-label {
                font-size: 0.875rem;
                color: $dark;
                text-align: left;
                line-height: 1rem;
                font-weight: get-weight(medium);
              }
            }
          }
        }

        &-right {
          flex: 1;

          .total-hospital-visits {
            margin-right: 0;
            width: 100%;

            .breakdown-item-inner-left {
              background-color: lighten($primary, 40%);
              color: $primary;
            }
          }

          .bottom-chart {
            width: 100%;
            border-radius: 0.5rem;
            background-color: $primary;
            min-height: 13.357rem;
            height: 100%;
            margin-top: 1rem;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            .chart-header {
              font-weight: get-weight(medium);
              padding: 0.5rem;
              @include flexCenter(row, space-between, flex-start);

              .chart-header-title,
              .daily-average {
                text-align: left;
                font-size: 0.875rem;
              }

              .daily-average {
                color: $secondary;
                opacity: 0.6;
              }

              .chart-header-title,
              .amount {
                color: $white;
                font-size: 1rem;
              }
            }

            .canvas-container {
              position: absolute;
              bottom: -0.2rem;
              right: -0.2rem;
              left: -0.2rem;
            }
          }
        }

        @media screen and (max-width: 48rem) {
          @include flexCenter(column, flex-start, flex-start);
          height: auto;

          &-left {
            display: none;
            flex: 1;
            height: auto;
            width: 100%;
            min-width: auto !important;
            margin-bottom: 1rem;

            .sales-statistics {
              height: auto;
              min-height: auto;
              max-height: auto;
              padding-bottom: 2.5rem;
            }
          }

          &-right {
            width: 100%;
          }
        }
      }

      .bottom-row-breakdown {
        margin: 1rem 0;
        height: 23.13rem;
        @include flexCenter(row, flex-start);

        &-left {
          min-width: 53rem;
          flex: 3;
          height: 100%;
          border-radius: 0.5rem;
          padding: 1rem 0;
          margin-right: 1rem;

          .activity-feed {
            min-height: auto;
          }
        }

        &-right {
          flex: 1;
          min-width: 16.88rem;
          height: 100%;

          &-inner {
            padding: 1rem;
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            overflow-y: auto;
            background-color: $white;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;

            &::-webkit-scrollbar {
              display: none;
            }

            @include transition(box-shadow 0.5s ease);

            &:hover {
              @include shadow(lighten($grey, 15%));
            }

            .top-header {
              @include flexCenter(row, space-between);

              h2 {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: get-weight(medium);
                color: $dark;
                margin: 0;
              }

              button.full-report {
                color: $primary;
                font-size: 0.875rem;
                font-weight: get-weight(bold);
                line-height: 1rem;
                text-decoration: underline;
                background: transparent;
                border: none;
              }
            }

            .sales-paragraph {
              color: $grey;
              font-size: 0.875rem;
              font-weight: get-weight(medium);
              line-height: 0.875rem;
              text-align: left;
              margin: 0.5rem 0 0 0;
            }

            .leaderboard-category-buttons {
              @include flexCenter(row, flex-start);
              overflow-x: auto;
              width: 100%;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none;
              margin: 0.5rem 0;

              &::-webkit-scrollbar {
                display: none;
              }

              .app-btn {
                margin-right: 0.5rem;
                width: auto;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .leadership-board {
              width: 100%;

              &-item {
                padding: 0.5rem 0;
                border-bottom: 1px solid lighten($grey, 20%);
                @include flexCenter(row, space-between, flex-start);

                p {
                  margin: 0.2rem 0;
                  line-height: 1rem;
                }

                .left-item {
                  flex: 2;
                  text-align: left;

                  .title {
                    font-size: 0.875rem;
                    color: $dark;
                    font-weight: get-weight(medium);
                  }

                  .subtitle {
                    font-size: 0.75rem;
                    color: $grey;
                    font-weight: get-weight(regular);
                  }
                }

                .right-item {
                  flex: 1;
                  text-align: right;

                  .price {
                    font-size: 0.875rem;
                    color: $dark;
                    font-weight: get-weight(medium);
                  }

                  .inner {
                    font-size: 0.75rem;
                    color: $darkGrey;
                    font-weight: get-weight(medium);
                    border-radius: 0.3rem;
                    display: inline-block;
                    text-align: right;
                    padding: 0.1rem 0.2rem;

                    &.success {
                      background-color: lighten($success, 30%);
                      color: $success;
                    }
                    &.error {
                      background-color: lighten($error, 45%);
                      color: $error;
                    }
                    &.royal {
                      background-color: lighten($iris, 10%);
                      color: $pitch;
                    }
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 48rem) {
          height: auto;
          @include flexCenter(column, flex-start, flex-start);

          &-left {
            flex: 1;
            min-width: 100%;
            margin-right: 0;
          }

          &-right {
            flex: 1;
            min-width: 100%;

            &-inner {
              max-height: 23.13rem;
            }
          }
        }
      }

      .submit-pitch-container {
        width: 100%;
        min-height: 40rem;
        box-sizing: border-box;
        border-radius: 0.375rem;

        @include flexCenter(row, flex-start, flex-start);

        .submit-pitch-left {
          flex: 1;
          height: 100%;
          margin-right: 3rem;
          position: relative;

          .submit-pitch-item {
            border-left: 1px solid $grey;
            height: 100%;
            min-height: 5rem;
            width: 100%;
            padding: 0 0 0 2rem;
            position: relative;

            h2 {
              font-size: 0.975rem;
              color: $dark;
              line-height: 1.5rem;
              margin: 0.5rem 0;
            }

            &-inner {
              min-height: 5rem;
              background-color: $white;
              font-size: 0.875rem;
              padding: 1rem;
              border-radius: 0.375rem;

              p {
                color: $darkGrey;
                line-height: 1rem;
                margin: 0;
              }
            }

            &::before {
              content: "";
              width: 1.5rem;
              height: 1.5rem;
              border-radius: 50%;
              border: 1px solid $success;
              display: block;
              text-align: center;
              line-height: 1.5rem;
              position: absolute;
              top: -0.75rem;
              left: -0.75rem;
              // margin: 0 auto;
              background: $secondary;
              color: $success;
              cursor: pointer;
            }
          }
        }

        .submit-pitch-right {
          height: 100%;
          flex: 1;

          &-inner {
            background-color: $white;
            padding: 1rem;

            h3 {
              font-size: 1.2rem;
              color: $dark;
              line-height: 1.5rem;
              font-weight: get-weight(medium);
            }

            p.subtitle {
              color: $darkGrey;
              line-height: 1rem;
            }
          }

          .app-btn {
            width: auto;
            border: 1px solid $primary;
            background-color: transparent;
            color: $primary;
          }
        }
      }
    }

    .dashboard-footer {
      height: 5rem;
      border-top: 1px solid lighten($grey, 15%);
      margin-top: 1rem;
      padding: 1rem;
      color: $darkGrey;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: get-weight(regular);

      @include flexCenter(row, space-between);

      a,
      button {
        text-decoration: none;
        color: $darkGrey;
        margin-right: 1rem;
      }

      button {
        border: none;
        background-color: transparent;
      }

      @media screen and (max-width: 33rem) {
        @include flexCenter(column, flex-start);

        button.send-feedback {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 19.75rem) {
        @include flexCenter(column, center);

        padding: 0.3rem;
      }
    }

    .history-container {
      width: 100%;
      box-sizing: border-box;

      .hospital-details {
        padding: 1.25rem 2rem;
        position: relative;
        height: auto;
        margin-bottom: 1rem;
        width: 100%;
        cursor: pointer;

        @include flexCenter(row, center);

        &-inner {
          padding: 1rem;
          margin: 0 auto;
          background-color: $primary;
          height: 100%;
          width: auto;
          display: inline-block;
          border-radius: 0.375rem;
          color: $white;
          text-align: left;

          i.fa {
            font-weight: get-weight(medium);
            margin-right: 0.3rem;
          }

          h2 {
            font-size: 1.1rem;
            text-transform: capitalize;
            font-weight: get-weight(medium);
          }

          ul {
            margin: 0 auto;
            list-style-type: none;
            padding: 0;
            @include flexCenter(row, center);

            li {
              margin-right: 1rem;
            }
          }

          button.view-gamification {
            background-color: transparent;
            border: 1px solid $secondary;
            color: $secondary;
            border-radius: 0.375rem;
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;

            &:hover {
              @include transition(all 0.5s ease);
              @include animation(zoom-in 0.5s ease);
            }
          }
        }
      }

      /* The actual timeline (the vertical ruler) */
      .timeline {
        position: relative;
        width: auto;
        margin: 0 auto;

        /* The actual timeline (the vertical ruler) */
        &:after {
          content: "";
          position: absolute;
          width: 0.375rem;
          background-color: white;
          top: 0;
          bottom: 0;
          left: 50%;
          margin-left: -6px;
        }

        /* Container around content */
        .history-container-item {
          padding: 0 2.5rem;
          position: relative;
          width: 50%;
          color: $white;

          &.hci-left,
          &.hci-right {
            ul {
              padding: 0;
              margin: 0;
              list-style-type: none;
            }
          }

          /* Place the history-container-item to the left */
          &.hci-left {
            left: 0;
            text-align: right;
            @include flexCenter(row, flex-end);

            ul {
              direction: rtl;
            }

            /* Add arrows to the left history-container-item (pointing right) */
            &:before {
              content: " ";
              height: 0;
              position: absolute;
              top: 50%;
              width: 0;
              z-index: 1;
              right: 1rem;
              border-width: 1rem 0 1rem 1rem;
            }
          }

          /* Place the history-container-item to the right */
          &.hci-right {
            left: 50%;
            text-align: left;

            /* Add arrows to the right history-container-item (pointing left) */
            &:before {
              content: " ";
              height: 0;
              position: absolute;
              top: 50%;
              width: 2rem;
              z-index: 1;
              left: 0.5rem;
              border-width: 1rem 1rem 1rem 0;
            }

            /* Fix the circle for history-container-items on the right side */
            &:after {
              left: -0.5rem;
            }
          }

          /* The circles on the timeline */
          &:after {
            content: "";
            position: absolute;
            width: 0.375rem;
            height: 100%;
            right: 0;
            top: 0;
            z-index: 1;
          }

          &.new {
            &.hci-left {
              &:before {
                border: medium solid $new;
                border-color: transparent $new transparent transparent;
              }
            }

            .history-container-item-content {
              // background-color: $new;
              color: $new;
            }

            &:after {
              background-color: $new;
              border: 4px solid $new;
            }
          }
          &.prospect {
            &.hci-right {
              &:before {
                border: medium solid $prospect;
                border-color: transparent transparent transparent $prospect;
              }
            }

            .history-container-item-content {
              // background-color: $prospect;
              color: $prospect;
            }

            &:after {
              background-color: $prospect;
              border: 4px solid $prospect;
            }
          }
          &.pitch {
            &.hci-left {
              &:before {
                border: medium solid $pitch;
                border-color: transparent $pitch transparent transparent;
              }
            }

            .history-container-item-content {
              // background-color: $pitch;
              color: $pitch;
            }

            &:after {
              background-color: $pitch;
              border: 4px solid $pitch;
            }
          }
          &.onboard {
            &.hci-right {
              &:before {
                border: medium solid $onboard;
                border-color: transparent transparent transparent $onboard;
              }
            }

            .history-container-item-content {
              // background-color: $onboard;
              color: $onboard;
            }

            &:after {
              background-color: $onboard;
              border: 4px solid $onboard;
            }
          }
          &.exodus {
            &.hci-left {
              &:before {
                border: medium solid $exodus;
                border-color: transparent $exodus transparent transparent;
              }
            }

            .history-container-item-content {
              // background-color: $exodus;
              color: $exodus;
            }

            &:after {
              background-color: $exodus;
              border: 4px solid $exodus;
            }
          }
          &.active {
            &.hci-right {
              &:before {
                border: medium solid $active;
                border-color: transparent transparent transparent $active;
              }
            }

            .history-container-item-content {
              // background-color: $active;
              color: $active;
            }

            &:after {
              background-color: $active;
              border: 4px solid $active;
            }
          }
          &.inactive {
            &.hci-left {
              &:before {
                border: medium solid $inactive;
                border-color: transparent $inactive transparent transparent;
              }
            }

            .history-container-item-content {
              // background-color: $inactive;
              color: $inactive;
            }

            &:after {
              background-color: $inactive;
              border: 4px solid $inactive;
            }
          }
          &.hibernated {
            &.hci-right {
              &:before {
                border: medium solid $hibernation;
                border-color: transparent transparent transparent $hibernation;
              }
            }

            .history-container-item-content {
              // background-color: $hibernation;
              color: $hibernation;
            }

            &:after {
              background-color: $hibernation;
              border: 4px solid $hibernation;
            }
          }

          &-content {
            padding: 0 1rem;
            position: relative;
            border-radius: 0.375rem;
            min-height: 5rem;
            // overflow-y: auto;
            width: 100%;

            h2 {
              font-size: 0.875rem;
              font-weight: get-weight(medium);
            }
          }
        }
      }

      /* Media queries - Responsive timeline on screens less than 600px wide */
      @media screen and (max-width: 48rem) {
        .hospital-details {
          &-inner {
            width: 100%;

            ul {
              margin: 0 auto;
              list-style-type: none;
              padding: 0;
              @include flexCenter(column, flex-start, flex-start);

              li {
                margin-right: 1rem;
              }
            }
          }
        }

        /* Place the timelime to the left */
        .timeline::after {
          left: 2rem;
        }

        /* Full-width history-container-items */
        .history-container-item {
          width: 100% !important;
          // padding-left: 3.375rem !important;
          // padding-right: 1.563rem !important;

          &-content {
            text-align: left;
            width: 100% !important;
          }

          /* Make sure that all arrows are pointing leftwards */
          &.new {
            &.hci-right {
              &:before {
                border: medium solid $new;
                border-color: transparent $new transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $new;
            // }
          }
          &.prospect {
            &.hci-left {
              &:before {
                border: medium solid $prospect;
                border-color: transparent $prospect transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $prospect;
            // }
          }
          &.pitch {
            &.hci-right {
              &:before {
                border: medium solid $pitch;
                border-color: transparent $pitch transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $pitch;
            // }
          }
          &.onboard {
            &.hci-left {
              &:before {
                border: medium solid $onboard;
                border-color: transparent $onboard transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $onboard;
            // }
          }
          &.exodus {
            &.hci-right {
              &:before {
                border: medium solid $exodus;
                border-color: transparent $exodus transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $exodus;
            // }
          }
          &.active {
            &.hci-left {
              &:before {
                border: medium solid $active;
                border-color: transparent $active transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $active;
            // }
          }
          &.inactive {
            &.hci-right {
              &:before {
                border: medium solid $inactive;
                border-color: transparent $inactive transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $inactive;
            // }
          }
          &.hibernated {
            &.hci-left {
              &:before {
                border: medium solid $hibernation;
                border-color: transparent $hibernation transparent transparent;
              }
            }

            // .history-container-item-content {
            //   background-color: $hibernation;
            // }
          }
          &:before {
            left: 0rem;
          }

          &.hci-left {
            &:before {
              @include transform(rotate(180deg));
              left: 0;
            }
          }
        }

        /* Make all right containers behave like the left ones */
        .hci-left {
          &:after {
            background-color: inherit !important;
            left: 1.5rem;
          }

          ul {
            direction: unset !important;
          }
        }

        .hci-right {
          left: 0 !important;

          &:after {
            left: 1.5rem !important;
          }
        }
      }
    }

    .hospitals-container,
    .loading-background,
    .pipeline-container,
    .agents-table-container,
    .support-container,
    .score-card,
    .tutorial-container {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: $white;
      border-radius: 0.375rem;

      &-top {
        padding: 0 1rem;
        height: 5.25rem;
        @include flexCenter(row, space-between);

        .left-options,
        .right-options {
          .app-btn {
            margin-top: 0;
            height: 2.75rem;
            width: auto;
          }
        }

        .left-options {
          @include flexCenter(row, center);

          .app-btn {
            margin-left: 0.5rem;
            width: 3rem;
          }
        }

        .right-options {
          @include flexCenter(row, center);

          .form-field {
            margin-top: 0;
            margin-right: 1rem;

            &-input {
              width: 11.563rem;
            }
          }
        }

        @media screen and (max-width: 48rem) {
          @include flexCenter(column, flex-start, flex-start);

          height: auto;
          padding: 1rem;

          .right-options {
            width: 100% !important;
            @include flexCenter(column, flex-start, flex-start);

            .form-field {
              margin-top: 0.5rem;

              &-input {
                width: 13rem;
              }
            }

            .app-btn {
              margin-top: 0.5rem;
            }
          }
        }
      }

      &-bottom {
        &-inner {
          padding: 0.5rem;

          &:after {
            clear: both;
            display: table;
            content: "";
          }

          .single-pipeline-item {
            float: left;
            width: 16rem;
            // height: 6.06rem;
            border: 1px solid $grey;
            padding: 0.5rem;
            text-align: left;
            margin: 0.5rem 0 0 0.5rem;

            &-title {
              font-weight: get-weight(medium);
              font-size: 0.875rem;
              line-height: 1rem;
              color: $darkGrey;
              text-transform: capitalize;
            }

            &-subtitle {
              font-weight: get-weight(regular);
              font-size: 0.875rem;
              line-height: 1rem;
              margin: 0.5rem 0;
              color: $darkGrey;

              a,
              span {
                font-weight: get-weight(medium);
              }

              a {
                color: $darkGrey;
              }
            }
          }
        }
      }
    }

    .loading-background {
      padding: 1rem;
      margin: 0 auto;
      text-align: center;
    }

    .support-container {
      padding: 2rem;
      width: 100%;
      height: 100%;
      overflow: auto;
      margin: 0 auto;

      &-item {
        min-width: 20.5rem;
        width: 31%;
        height: 13.45rem;
        background-color: $dark;
        margin-right: 1rem;
        margin-top: 1rem;
        position: relative;
        overflow: hidden;
        float: left;

        @media screen and (max-width: 48rem) {
          width: 100%;
        }

        &-inner {
          position: absolute;

          width: 100%;
          height: 100%;
          background-color: $white;
          border-radius: 0.5rem;
          padding: 1rem;
          @include flexCenter(row, center);

          .inner-container {
            text-align: left;

            h4 {
              font-size: 1.05rem;
              line-height: 1.5rem;
              font-weight: get-weight(bold);
              margin: 0;
              text-align: left;
              color: $dark;
            }

            p {
              font-size: 0.975rem;
              line-height: 1.2rem;
              font-weight: get-weight(regular);
              color: $grey;
              border-bottom: 1px solid lighten($grey, 20%);
              padding: 0.8rem 0;
              text-align: left;
            }

            div.download-link {
              border: 1px solid lighten($grey, 20%);
              padding: 0.2rem 0.5rem;
              border-radius: 0.3rem;
              width: fit-content;
              margin-left: auto;

              i.fa {
                color: $grey;
              }

              a,
              button {
                margin-right: 0.3rem;
                color: dodgerblue;
                text-decoration: none;
              }

              button {
                border: none;
                background: transparent;
              }
            }
          }
        }
      }
    }

    .prospect-buttons {
      min-height: 5rem;
    }

    .leads-arena-buttons,
    .prospect-buttons {
      width: 100%;
      display: block;
      min-height: 3rem;
      margin-bottom: 2rem;

      &:after {
        clear: both;
        display: table;
        content: "";
      }

      .app-btn {
        width: auto;
        float: left;
        margin-right: 1rem;

        &.inactive {
          opacity: 0.5;
        }

        &.active {
          opacity: 1;
        }
      }
    }

    .activity-feed,
    .pipeline-container,
    .add-hospital {
      height: 100%;
      min-height: 40rem;
      text-align: center;
    }

    .add-hospital {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      background-color: $white;
      border-radius: 0.375rem;
      padding: 0.5rem;
    }

    .current-revenue {
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 0.375rem;
      padding: 1rem;
      overflow-y: auto;

      &-header {
        font-size: 1.2rem;
        line-height: 1rem;
        font-weight: get-weight(medium);
        color: $dark;
      }

      &-chart {
        height: auto;
        min-height: 15rem;
        max-height: 17.13rem;

        canvas {
          height: auto;
          min-height: 15rem;
          max-height: 17.13rem;
        }
      }

      .highest-cities {
        margin-top: 0.3rem;
        @include flexCenter(row, flex-start);

        @media screen and (max-width: 48rem) {
          @include flexCenter(column, flex-start, flex-end);

          &-data {
            &-item {
              @include flexCenter(row, center);
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              margin-top: 0.5rem;
            }
          }
        }

        &-header {
          font-size: 0.875rem;
          font-weight: get-weight(medium);
          margin-right: 0.5rem;
        }

        &-data {
          @include flexCenter(row, flex-start);

          &-item {
            @include flexCenter(row, center);
            margin-right: 0.5rem;

            &-rank {
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 1rem;
              margin-right: 0.3rem;
            }

            &-value {
              font-size: 0.875rem;
              font-weight: get-weight(medium);
            }
          }
        }
      }
    }

    .score-card {
      min-height: 40rem;
      padding: 2rem;

      .container-fluid {
        padding: 0;

        .row {
          padding: 1.5rem 0;
          border: 1px solid $light;
          border-radius: 0.375rem;
          margin: 0.5rem 0;
        }
      }

      &-title {
        font-weight: get-weight(medium);
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: $dark;

        span {
          color: $error;
          text-transform: capitalize;
        }
      }

      &-item {
        color: $dark;
        margin: 0.5rem 0;

        &-label {
          margin-bottom: 0.5rem;
          line-height: 1rem;
          font-size: 0.75rem;
          font-weight: get-weight(bold);
          text-transform: uppercase;
          color: $darkGrey;
        }

        &-value {
          width: 100%;
          height: 2.5rem;
          background-color: $white;
          border: 1px dashed $light;
          border-radius: 0.375rem;
          padding: 0.5rem 1rem;
          line-height: 1.5rem;
          font-size: 0.875rem;
          font-weight: get-weight(regular);
          text-transform: uppercase;

          &-progress {
            width: 100%;
            min-height: 2.5rem;

            border: 1px dashed $light;
            padding: 1rem;
            border-radius: 0.375rem;

            &-bar {
              width: 100%;
              height: 0.25rem;
              background-color: $light;
              border-radius: 5rem;
              overflow: hidden;

              &-inner {
                height: 0.25rem;
                background-color: $primary;
                width: 60%;
              }
            }

            .total-score {
              margin-top: 0.25rem;
              line-height: 1rem;
              font-size: 0.75rem;
              font-weight: get-weight(bold);
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .no-items {
    background-color: $secondary;
    padding: 1rem;
    color: $dark;
    font-weight: get-weight(medium);
    border-radius: 0.3rem;
    width: auto;
    display: inline-block;

    p {
      margin: 0 auto;
      display: inline-block;
    }
  }

  .error {
    color: $error;
  }

  .primary {
    color: $primary;
  }

  .neutral {
    color: $darkGrey;
  }
}

.login,
.not-available {
  width: 100%;
  height: 100vh;
  background-color: $secondary;
  @include flexCenter(row, center);

  &-inner {
    width: 26.13rem;
    height: 31.13rem;

    &-title {
      font-weight: get-weight(medium);
      font-size: 2rem;
      line-height: 2rem;
      text-align: center;
      color: $dark;
    }

    &-subtitle {
      font-weight: get-weight(regular);
      color: $darkGrey;
      font-size: 1rem;
      text-align: center;
      line-height: 1.5rem;
    }

    .form-container {
      width: 100%;
      height: auto;
      max-height: 25.5rem;
      overflow-y: auto;
      background-color: $white;
      border-radius: 0.375rem;
      padding: 2rem;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .form-field {
        &-input {
          background-color: $secondary;
          border: none;
        }
      }

      .forgot-password {
        margin: 1rem 0 0 0;
        text-align: left;
        width: 100%;

        &-link {
          margin-right: auto;
          text-decoration: none;
          font-weight: get-weight(medium);
          color: $primary;
          font-size: 0.875rem;
        }
      }

      .app-btn {
        width: 100%;
      }
    }

    .download-apk {
      margin-top: 2rem;
      text-align: center;

      p {
        color: $dark;
        line-height: 1.3rem;
        font-size: 1rem;
        font-weight: get-weight(medium);

        a {
          margin-left: 0.3rem;
          color: $primary;
          text-decoration: none;
        }
      }
    }
  }
}

.offline-notice {
  z-index: 200;
  background-color: $error;
  color: $secondary;
  height: 2.5rem;
  width: 20rem;
  font-weight: get-weight(medium);
  border-radius: 0.375rem;
  position: fixed;
  left: 50%;
  top: 5%;
  @include transition(opacity 5s ease);

  &.online {
    background-color: $success;
    opacity: 0;
  }

  i.fa {
    margin-right: 0.3rem;
  }

  @include transform(translate(-50%, -5%));
  @include flexCenter(row, center);
}

.hospital_orders {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @include flexCenter(row, center);

  &_body {
    width: 65%;
    height: 100%;
    max-height: 85%;
    overflow-y: scroll;
    background-color: $white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 5px solid $primary;

    h4 {
      text-align: left;
      color: $dark;
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin: 0 0 1rem 0;
      font-weight: get-weight(medium);
    }

    .order-buttons {
      margin: 1rem 0;

      &-item {
        background-color: transparent;
        border: none;
        border-bottom: 3px solid $primary;
        text-transform: uppercase;
        padding: 0.5rem;
        font-weight: get-weight(medium);
        color: $primary;
        width: 6rem;
        font-size: 0.75rem;
      }
    }
  }

  &_cadre {
    padding: 1.5rem;
    border: 1px solid $dew;
    text-align: center;
    border-radius: 0.5rem;
    border: 1px solid $neutral;
    background-color: $white;

    &:nth-child(2) {
      margin: 0 1rem;
    }

    &_icon {
      @include flexCenter(row, center);
      margin: 0 auto;
      align-self: center;
      width: 3rem;
      height: 3rem;
      border-radius: 0.25rem;
      background-color: $neutral;
      color: $primary;

      i {
        font-size: 1.5rem;
      }
    }

    &_label {
      margin: 1rem 0;
      font-size: 0.75rem;
      color: $dark;
      font-weight: get-weight(medium);
      text-transform: uppercase;
    }

    &_value {
      padding: 0.5rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: get-weight(medium);
      background-color: $primary;
      color: $white;
      border: 0.5rem;
      border-radius: 0.5rem;
    }
  }
}
