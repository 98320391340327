table {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 0.875rem;
  font-weight: get-weight(medium);
  text-align: left;

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;

    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      height: 5rem;
      border: none;
      border-bottom: 1px solid $iris;
      position: relative;
      text-align: right;
      padding-left: 50%;

      button,
      .product-color {
        margin-top: 0.3rem;
        float: right;

        &:after {
          clear: both;
          display: table;
          content: "";
        }
      }
    }

    td:before {
      content: attr(data-label);
      float: left;
      white-space: nowrap;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  a {
    color: $dark;
  }

  td {
    color: $dark;
    font-weight: get-weight(regular);
    margin: 0 auto;
    padding: 1.5rem 0.5rem;
    background-color: $white;
    border-bottom: 1px solid lighten($grey, 20%);
    @include transition(background-color 0.5s ease);

    .product-color {
      @include flexCenter(row, flex-start);
      width: auto;

      &-display {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
    }
  }

  th {
    color: $darkGrey;
    padding: 1rem 0.5rem;
    border-bottom: 2px solid lighten($grey, 10%);
    background-color: $secondary;
  }

  tbody {
    tr {
      padding-top: 1rem;
      background-color: $secondary;

      &:hover {
        td {
          background-color: $secondary !important;
        }
      }
    }

    .hospital-status {
      border-radius: 10000px;
      padding: 1px 10px;
    }

    .hospital-status.active {
      background-color: rgb(44, 180, 225);
      color: white;
    }

    .hospital-status.newly-active {
      background-color: crimson;
      color: white;
    }

    .hospital-status.danger {
      background-color: lightcoral;
    }

    .hospital-status.add-value {
      background-color: coral;
    }

    .hospital-status.check-in {
      background-color: sandybrown;
    }

    .hospital-status.upsell {
      background-color: lightgreen;
    }

    .hospital-status.loyal {
      background-color: violet;
    }

    button.status {
      width: 5.187rem;
      height: 1.25rem;
      border-radius: 1rem;
      border: none;
      background-color: $grey;
      font-weight: get-weight(medium);
      font-size: 0.75rem;
      line-height: 1.25rem;

      @include flexCenter(row, center);

      &.error {
        background-color: $error;
      }
      &.view {
        background-color: $grey;
      }
      &.primary {
        background-color: $primary;
      }
      &.new {
        background-color: $new;
      }
      &.prospect {
        background-color: $prospect;
      }
      &.pitch {
        background-color: $pitch;
      }
      &.onboard {
        background-color: $onboard;
      }
      &.exodus {
        background-color: $exodus;
      }
      &.active {
        background-color: $active;
      }
      &.assign {
        background-color: $active;
      }
      &.inactive {
        background-color: $inactive;
      }
      &.hibernation,
      &.hibernate {
        background-color: $hibernation;
      }

      color: $white !important;
    }
  }
}
