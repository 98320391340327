@import "./config";
@import "./mixins";
@import "./utilities";

%btn {
  background-color: $primary;
  color: $white;
  border-radius: 0.187rem;
  min-width: 5.25rem;
  height: 2.25rem;
  border: none;
  font-size: 0.875rem;
  font-weight: get-weight(medium);
  cursor: pointer;
  margin-top: 1rem;

  @include transition(transform 0.5s ease);

  i.fa {
    margin-right: 0.3rem;
  }

  &:hover {
    @include transform(scale(1.04));
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
  }

  &.active {
    opacity: 1;
  }
}

.app-btn {
  @extend %btn;
}

.gamification-btn {
  @extend %btn;

  background-color: $white;
  color: $primary;
}

.change-country {
  @extend %btn;

  background-color: transparent;
  color: $primary;
  height: auto;
}

.close-btn {
  background-color: $error;
  margin-left: 0.5rem;
}

.loading-button {
  font-weight: get-weight(medium);
  font-size: 0.875rem;
  color: $primary;
  margin: 1rem 0;

  span {
    margin-left: 0.5rem;
  }
}
