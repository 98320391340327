@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main-usertype {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.usertype {
  display: flex;
  width: 80%;
  cursor: pointer;
}
.usertype-sales {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  width: calc(100% / 3);
  color: white;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}
.usertype-sales:nth-child(1) {
  background-color: #888787;
  border-radius: 10px 0 0 10px;
}
.usertype-sales:nth-child(2) {
  background-color: #2f49d1;
}
.usertype-sales:nth-child(3) {
  border-radius: 0 10px 10px 0;
  background-color: #6271bb;
}

.main-usertype .header {
  margin-top: 5rem;
  color: #172b4d;
  line-height: 1.3rem;
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 600;
}

.main-usertype .download-apk,
.not-available-inner .download-apk {
  margin-top: 2rem;
  text-align: center;
}

.main-usertype .download-apk p,
.not-available-inner .download-apk p {
  color: #172b4d;
  line-height: 1.3rem;
  font-size: 1rem;
  font-weight: 600;
}

.main-usertype .download-apk p a,
.not-available-inner .download-apk p a {
  margin-left: 0.3rem;
  color: #2f49d1;
  text-decoration: none;
}

.marketing-top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-content: center;
  min-height: 70vh;
}

@media screen and (max-width: 48rem) {
  .marketing-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .usertype {
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
    padding: 0 2rem;
  }

  .usertype-sales {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    width: 100%;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }

  .usertype-sales:nth-child(1) {
    background-color: #888787;
    border-radius: 10px 10px 0 0;
  }
  .usertype-sales:nth-child(2) {
    background-color: #2f49d1;
  }
  .usertype-sales:nth-child(3) {
    border-radius: 0 0 10px 10px;
    background-color: #6271bb;
  }
}

.marketing-top .labels {
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 95%;
  height: 30vh;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #e0e2eb;
}

@media screen and (max-width: 48rem) {
  .marketing-top .labels {
    width: 100%;
    font-size: 1.2rem;
  }
}

.assign_button {
  justify-self: center;
  align-self: center;
  font-size: 1rem;
  padding: 1rem 2rem;
  border-radius: 5px;
}

.pitch-main {
  /* min-height: 70vh; */
  min-width: 40%;
  /* padding: 1re 2rem; */
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  margin-bottom: 2rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.checklist-main {
  min-height: 50vh;
  min-width: 40%;
  /* padding: 1re 2rem; */
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  margin-bottom: 2rem;
}

.pitch-data {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

.pitch-data2 {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  min-width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.pitch-data .label,
.pitch-data2 .label {
  min-width: 50%;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem;
}

.pitch-data .value,
.pitch-data2 .value {
  min-width: 40%;
  padding: 1rem;
}

.pitch-data .pitch-buttons .pitch-data2 .pitch-buttons {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.pitch-buttons {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  margin: 1rem 0;
}

.pitch-buttons .status.edit,
.appointment-buttons .status.edit,
.pitch-buttons .status.primary,
.pitch-buttons .status.deck {
  width: 5.187rem;
  height: 1.35rem;
  border-radius: 1rem;
  border: none;
  background-color: #b6b6b6;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff !important;
  background-color: #b73030;
}
.pitch-buttons .status.primary {
  background-color: #2f49d1;
}

.pitch-buttons .status.deck {
  width: max-content;
  padding: 0 1rem;
  background-color: #2f49d1;
}

.upload-container {
  margin-top: 1rem;
}

.upload {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 282px;
  background-color: #f2f2f2;
  border: 2px dashed #afafaf;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
}
.upload img {
  max-width: 100%;
  min-width: 70%;
  max-height: 100%;
  min-height: 70%;
  background-repeat: no-repeat;
}

.upload-label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px;
  cursor: pointer;

  /* span {
      display: block;
      font-size: 16px;
      font-weight: bold;
      color: #555;
    } */
}

.upload-icon {
  align-self: center;
  position: absolute;
  top: 35%;
  right: 40%;
}
.upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.customer-main {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-main .inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner .header {
  font-size: 2rem;
  font-weight: 900;
}

.inner h3 {
  margin-top: 1rem;
  font-weight: 600;
}

.inner .list {
  display: flex;
  gap: 3rem;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  justify-content: space-between;
}

.maintain-container {
  position: relative;
  border: 2px solid #2f49d1;
  border-radius: 10px;
  padding: 3rem;
  height: 17vh;
  width: 23vw;
}
.maintain-container button {
  position: absolute;
  bottom: -20px;
  right: 30%;
  width: 10rem;
  height: 3.25rem;
  border-radius: 1.5rem;
  border: none;
  background-color: #2f49d1;
  font-weight: 700;
  font-size: 1.15rem;
  line-height: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: span center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff !important;
}

.maintain-container .grow {
  background-color: #fff;
  color: #2f49d1 !important;
  border: 2px solid #2f49d1;
  right: 30%;
}

.tabsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.reqTab,
.activeTab {
  width: calc(100% / 2);
  background-color: #d6dbdf;
  border-radius: 10px 0 0 10px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: #2f49d1;
  font-weight: 600;
  font-size: 1.2rem;
}

.activeTab {
  background-color: #2f49d1;
  color: #d6dbdf;
}

.reqTab:nth-child(2),
.activeTab:nth-child(2) {
  border-radius: 0 10px 10px 0;
}

.back {
  cursor: pointer;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: #2f49d1;
  margin-left: 1rem;
}

.navigation {
  /* background-color: #2f49d1; */
  display: flex;
  padding: 2.7rem;
  margin-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
}

.navigation_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation_container_step,
.navigation_container_active {
  background-color: #fff;
  color: #2f49d1;
  border-radius: 50%;
  font-weight: 600;
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e5c76;
  cursor: default;
  transition: 0.5s ease-in-out;
}

.navigation_container_step2,
.navigation_container_active2 {
  background-color: #fff;
  color: #2f49d1;
  border-radius: 50%;
  font-weight: 600;
  width: 120px;
  height: 120px;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e5c76;
  cursor: default;
  transition: 0.5s ease-in-out;
  text-align: center;
}

.navigation_container_active,
.navigation_container_active2 {
  background-color: #2f49d1;
  border: none;
  color: #fff;
}

.navigation_container_line {
  width: 50px;
  height: 2px;
  margin-bottom: 10px;
  background-color: #5b72e2;
}

.checklist-button {
  display: flex;
  align-self: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.check_button {
  padding: 0.5rem 1.5rem;
  background-color: #441ab7;
  color: #fff;
  border-radius: 5px;
  margin-left: 1rem;
  cursor: pointer;
}

.nerve {
  color: #114b29;
  font-weight: 700;
  font-size: 1.2rem;
}

.no-nerve {
  font-weight: 700;
  font-size: 1.2rem;
}

.yes {
  color: green;
}

.no {
  color: tomato;
}

.active-actions {
  display: flex;
  justify-content: center;
  min-height: 50vh;
}

.single-action {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.drip {
  height: 150px;
  border-radius: 5px;
  border: 3px solid #2f49d1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  flex-basis: calc(100% / 4);
  box-sizing: border-box;
}

.drip-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-left: 5%;
}

.selected {
  background: #2f49d1;
  color: #fff;
}

.log-note {
  font-size: 20px;
  color: #000000;
  margin: 1rem 0;
}

.logContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-top: 3rem;
}

.logContent .find-location {
  background-color: #bea8ec;
  color: #4d26a0;
  border-radius: 5px;
  width: 80%;
  text-align: center;
  padding: 0.7rem;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
}

.log-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.log-actions .log-back {
  border: 1px solid #b3b0b0;
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.log-actions .log-save {
  background-color: #4d26a0;
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 16px;
  color: #ffff;
  cursor: pointer;
  outline: 0;
  border: none;
}

.log-actions .log-save-disable {
  outline: 0;
  border: none;
  background: rgba(77, 38, 160, 0.7);
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 16px;
  color: #f4f4f4f4;
  cursor: pointer;
}

.cuurent-location {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.coords {
  color: #4d26a0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.log-data {
  width: 100%;
  height: 312px;
  flex-shrink: 0;
  border: 1px solid rgba(77, 38, 160, 0.1);
  background: rgba(238, 232, 250, 0.5);
  padding: 2rem;
}

.log-data-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.log-data-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.log-data-bottom {
  width: 100%;
  height: 206px;
  flex-shrink: 0;
  border: 1px solid #4d26a0;
  margin-top: 1rem;
  padding: 1rem 2.5rem;
}

.log-data-bottom-name {
  color: #4d26a0;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.log-data-bottom-address {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.score-main {
  min-height: 70vh;
  min-width: 40%;
  margin: 2rem;
  /* padding: 1re 2rem; */
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.score-inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.score-value {
  display: flex;
}

@media screen and (max-width: 48rem) {
  .pitch-data {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .pitch-data2 {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    min-width: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  }

  .pitch-data .label,
  .pitch-data2 .label {
    min-width: 40%;
    max-width: 40%;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 1rem;
  }

  .pitch-data .value,
  .pitch-data2 .value {
    min-width: 40%;
    max-width: 55%;
    padding: 1rem;
    font-size: 0.9rem;
  }

  .log-actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 1rem;
  }

  .log-actions .log-back {
    padding: 0.3rem 0.8rem;
    font-size: 14px;
  }

  .log-actions .log-save {
    padding: 0.3rem 1.5rem;
    font-size: 12px;
  }

  .log-actions .log-save-disable {
    padding: 0.3rem 1.5rem;
    font-size: 12px;
  }

  .cuurent-location {
    font-size: 17px;
  }

  .navigation {
    /* background-color: #2f49d1; */
    padding: 1.7rem;
    margin-bottom: 1rem;
  }

  .navigation_container_step,
  .navigation_container_active {
    width: 37px;
    height: 37px;
  }

  .navigation_container_step2,
  .navigation_container_active2 {
    width: 75px;
    height: 75px;
    padding: 0.7rem;

    font-size: 0.69rem;
  }

  .navigation_container_line {
    width: 20px;
  }

  .customer-main {
    text-align: center;
  }

  .inner .header {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .inner .sub-header {
    margin-top: 1.2rem;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .inner .list {
    display: flex;
    gap: 2rem;
    font-size: 1rem;
    margin-top: 1.2rem;
    justify-content: space-between;
  }

  .maintain-container {
    position: relative;
    border: 2px solid #2f49d1;
    border-radius: 10px;
    padding: 1.5rem;
    height: 17vh;
    width: 50%;
  }
  .maintain-container button {
    position: absolute;
    right: 0;
    font-size: 1.1rem;
  }

  .maintain-container .grow {
    right: 0;
  }
  .pitch-main {
    min-width: 100%;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #b5cbe4;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #eafbef;
}
