@import "./config";
@import "./mixins";
@import "./utilities";

.search-query {
  width: 16.625rem;
  height: 2.75rem;
  background-color: $secondary;
  border-radius: 0.375rem;

  @include flexCenter(row, center);

  &-input {
    flex: 4;
    width: 100%;
    max-width: 13.304rem;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 0.5rem;
    color: $darkGrey;
  }

  &-icon {
    flex: 1;
    width: 100%;
    max-width: 3.321rem;
    color: $primary;

    @include flexCenter(row, center);
  }
}

.form-field,
.react-datepicker-wrapper {
  margin-top: 0.5rem;

  @include flexCenter(column, flex-start, flex-start);

  &-label,
  .date-picker-label {
    color: $darkGrey;
    line-height: 1.5rem;
    margin: 0.5rem 0;

    i.fa.fa-asterisk {
      color: $error;
      font-size: 0.75rem;
    }
  }

  p.date-picker-label {
    margin-bottom: 0;
  }

  &-input,
  input {
    width: 100%;
    height: 2.75rem;
    background-color: $white;
    padding: 0 0.5rem;
    border-radius: 0.375rem;
    border: 1px solid lighten($grey, 10%);
    color: $primary;
    outline: none;
  }

  .form-field-input.formatted {
    @include flexCenter(row, flex-start);
  }

  .error-message {
    color: $error;
    // margin-top: 0.5rem;
    font-size: 0.875rem;
  }

  textarea {
    height: 100% !important;
    padding: 0.5rem;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/arrow_down.png) no-repeat right center;
    background-size: 0.875rem;
  }
}

.ohw-radio-container {
  .radio-message {
    margin: 0 0.5rem;
  }
}

.demo-wrapper {
  a {
    color: $dark;
    font-weight: get-weight(medium);
    text-decoration: none;
  }

  .demo-editor {
    width: 100%;
    min-height: 10rem;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 0.187rem;
    padding: 0.5rem;
  }
}

.ohw-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 0.875rem !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 10rem;

  .checkbox-message {
    color: $primary;
    font-weight: get-weight(medium);
    margin-left: 1.5rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox-checkmark {
      background-color: $primary;

      &:after {
        display: block;
      }
    }
  }

  .checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    background-color: $secondary;
    border-radius: 50%;

    @include transition(all 0.5s ease);

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 7px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkbox-checkmark {
    background-color: $primary;
  }
}

form {
  h2.form-header {
    font-size: 1rem;
    text-align: left;
    line-height: 1.5rem;
    margin: 0;
    color: $dark;
    font-weight: get-weight(medium);

    i.fa {
      font-size: 0.75rem;
    }
  }
}
