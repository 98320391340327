@keyframes carousel-slide {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}
@keyframes rotating {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(370deg);
  }
}
@keyframes spin {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.2);
  }
  80%,
  100% {
    transform: scale(0);
  }
}
@keyframes pumping {
  0% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -o-transform: scale(0.95);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -o-transform: scale(0.95);
  }
}
@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
}
@keyframes tick {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
  }
  90% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
  }
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes move-text-color {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinkCursor {
  0%,
  75% {
    opacity: 1;
  }
  76%,
  100% {
    opacity: 0;
  }
}
@keyframes toggleVisibility {
  0%,
  75% {
    visibility: visible;
  }
  76%,
  100% {
    visibility: hidden;
  }
}
@keyframes animate-timeline {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
@keyframes expand-menu-animation {
  from {
    width: 0;
  }
  to {
    width: 20rem;
  }
}
.blink-cursor {
  animation: blinkCursor 1s steps(3) infinite;
  -webkit-animation: blinkCursor 1s steps(3) infinite;
  -moz-animation: blinkCursor 1s steps(3) infinite;
  -o-animation: blinkCursor 1s steps(3) infinite;
}

.toggle-visibility {
  animation: toggleVisibility 1s ease;
  -webkit-animation: toggleVisibility 1s ease;
  -moz-animation: toggleVisibility 1s ease;
  -o-animation: toggleVisibility 1s ease;
}

.zoom-in {
  animation: zoom-in 0.5s ease;
  -webkit-animation: zoom-in 0.5s ease;
  -moz-animation: zoom-in 0.5s ease;
  -o-animation: zoom-in 0.5s ease;
}

.slide-left {
  animation: slide-left 0.5s ease;
  -webkit-animation: slide-left 0.5s ease;
  -moz-animation: slide-left 0.5s ease;
  -o-animation: slide-left 0.5s ease;
}

.slide-right {
  animation: slide-right 0.5s ease;
  -webkit-animation: slide-right 0.5s ease;
  -moz-animation: slide-right 0.5s ease;
  -o-animation: slide-right 0.5s ease;
}

.slide-up {
  animation: slide-up 0.5s ease;
  -webkit-animation: slide-up 0.5s ease;
  -moz-animation: slide-up 0.5s ease;
  -o-animation: slide-up 0.5s ease;
}

.slide-down {
  animation: slide-down 0.5s ease;
  -webkit-animation: slide-down 0.5s ease;
  -moz-animation: slide-down 0.5s ease;
  -o-animation: slide-down 0.5s ease;
}

.pumping {
  animation: pumping 1s linear infinite;
  -webkit-animation: pumping 1s linear infinite;
  -moz-animation: pumping 1s linear infinite;
  -o-animation: pumping 1s linear infinite;
}

.fade-in {
  animation: fade-in 0.5s ease;
  -webkit-animation: fade-in 0.5s ease;
  -moz-animation: fade-in 0.5s ease;
  -o-animation: fade-in 0.5s ease;
}

.fade-out {
  animation: fade-out 2s ease;
  -webkit-animation: fade-out 2s ease;
  -moz-animation: fade-out 2s ease;
  -o-animation: fade-out 2s ease;
}

.search-query {
  width: 16.625rem;
  height: 2.75rem;
  background-color: #f0f2fa;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.search-query-input {
  flex: 4;
  width: 100%;
  max-width: 13.304rem;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 0.5rem;
  color: #888787;
}
.search-query-icon {
  flex: 1;
  width: 100%;
  max-width: 3.321rem;
  color: #2f49d1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.form-field,
.react-datepicker-wrapper {
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.form-field-label,
.form-field .date-picker-label,
.react-datepicker-wrapper-label,
.react-datepicker-wrapper .date-picker-label {
  color: #888787;
  line-height: 1.5rem;
  margin: 0.5rem 0;
}
.form-field-label i.fa.fa-asterisk,
.form-field .date-picker-label i.fa.fa-asterisk,
.react-datepicker-wrapper-label i.fa.fa-asterisk,
.react-datepicker-wrapper .date-picker-label i.fa.fa-asterisk {
  color: #ff0000;
  font-size: 0.75rem;
}
.form-field p.date-picker-label,
.react-datepicker-wrapper p.date-picker-label {
  margin-bottom: 0;
}
.form-field-input,
.form-field input,
.react-datepicker-wrapper-input,
.react-datepicker-wrapper input {
  width: 100%;
  height: 2.75rem;
  background-color: #ffffff;
  padding: 0 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #d0d0d0;
  color: #2f49d1;
  outline: none;
}
.form-field .form-field-input.formatted,
.react-datepicker-wrapper .form-field-input.formatted {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.form-field .error-message,
.react-datepicker-wrapper .error-message {
  color: #ff0000;
  font-size: 0.875rem;
}
.form-field textarea,
.react-datepicker-wrapper textarea {
  height: 100% !important;
  padding: 0.5rem;
}
.form-field select,
.react-datepicker-wrapper select {
  -webkit-appearance: none;
  appearance: none;
  background: url(../images/arrow_down.png) no-repeat right center;
  background-size: 0.875rem;
}

.ohw-radio-container .radio-message {
  margin: 0 0.5rem;
}

.demo-wrapper a {
  color: #172b4d;
  font-weight: 600;
  text-decoration: none;
}
.demo-wrapper .demo-editor {
  width: 100%;
  min-height: 10rem;
  background-color: #ffffff;
  border: 1px solid #b6b6b6;
  border-radius: 0.187rem;
  padding: 0.5rem;
}

.ohw-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 0.875rem !important;
  -webkit-user-select: none;
  user-select: none;
  max-width: 10rem;
}
.ohw-checkbox-container .checkbox-message {
  color: #2f49d1;
  font-weight: 600;
  margin-left: 1.5rem;
}
.ohw-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ohw-checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #2f49d1;
}
.ohw-checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}
.ohw-checkbox-container .checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #f0f2fa;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.ohw-checkbox-container .checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.ohw-checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #2f49d1;
}

form h2.form-header {
  font-size: 1rem;
  text-align: left;
  line-height: 1.5rem;
  margin: 0;
  color: #172b4d;
  font-weight: 600;
}
form h2.form-header i.fa {
  font-size: 0.75rem;
}

.change-country,
.gamification-btn,
.app-btn {
  background-color: #2f49d1;
  color: #ffffff;
  border-radius: 0.187rem;
  min-width: 5.25rem;
  height: 2.25rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
  transition: transform 0.5s ease;
}
.change-country i.fa,
.gamification-btn i.fa,
.app-btn i.fa {
  margin-right: 0.3rem;
}
.change-country:hover,
.gamification-btn:hover,
.app-btn:hover {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
}
.change-country:disabled,
.gamification-btn:disabled,
.app-btn:disabled,
.disabled.change-country,
.disabled.gamification-btn,
.disabled.app-btn {
  opacity: 0.5;
}
.active.change-country,
.active.gamification-btn,
.active.app-btn {
  opacity: 1;
}

.gamification-btn {
  background-color: #ffffff;
  color: #2f49d1;
}

.change-country {
  background-color: transparent;
  color: #2f49d1;
  height: auto;
}

.close-btn {
  background-color: #ff0000;
  margin-left: 0.5rem;
}

.loading-button {
  font-weight: 600;
  font-size: 0.875rem;
  color: #2f49d1;
  margin: 1rem 0;
}
.loading-button span {
  margin-left: 0.5rem;
}

.ohw-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ohw-modal-inner {
  width: auto;
  max-width: 35rem;
  min-width: 28rem;
  height: auto;
  max-height: 80vh;
  background-color: #ffffff;
  border-radius: 0.187rem;
  padding: 1rem;
  position: relative;
  color: #172b4d;
}
@media screen and (max-width: 48rem) {
  .ohw-modal-inner {
    min-width: 80%;
    max-width: 80%;
  }
}
.ohw-modal-inner button.close {
  width: 2rem;
  height: 2rem;
  background-color: #2f49d1;
  color: #ffffff;
  border: none;
  border-radius: 1rem;
  position: absolute;
  right: -1rem;
  top: -1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ohw-modal-inner-body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 70vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.ohw-modal-inner-body::-webkit-scrollbar {
  display: none;
}
.ohw-modal-inner .modal-title {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 900;
  text-align: left;
}
.ohw-modal-inner .modal-title.confirm-validate-title {
  margin-top: 1rem;
  text-align: center;
}
.ohw-modal-inner .modal-title span {
  color: #ff0000;
}
.ohw-modal-inner .confirm-validate-icon {
  font-size: 3rem;
  color: #fae20c;
  text-align: center;
}
.ohw-modal-inner .confirm-validate-text {
  text-align: center;
}
.ohw-modal-inner .confirm-validate-text,
.ohw-modal-inner .modal-body-text {
  font-size: 1.1rem;
}
.ohw-modal-inner .modal-body-text {
  text-align: left;
  margin: 1rem 0;
  font-weight: 400;
}
.ohw-modal-inner .complaint-modal-items {
  margin: 1rem 0;
}
.ohw-modal-inner .complaint-modal-items.no-list {
  padding: 0;
  list-style-type: none;
}
.ohw-modal-inner .complaint-modal-items-single {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #172b4d;
  text-align: left;
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e9e9e9;
}
.ohw-modal-inner .complaint-modal-items-single span {
  margin: 0 0.5rem;
  font-weight: 900;
}
.ohw-modal-inner .complaint-modal-items-single span.update {
  color: #4d8c5e;
}
.ohw-modal-inner .complaint-modal-items-single i.fa {
  color: #ff0000;
}
.ohw-modal-inner .modal-center-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ohw-modal-inner .modal-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.ohw-modal-inner .modal-buttons .close-btn,
.ohw-modal-inner .modal-center-buttons .close-btn {
  margin-left: 1rem;
  width: auto;
}
.ohw-modal-inner .chart-modal-inner {
  padding: 1rem;
}
.ohw-modal-inner .chart-modal-inner .chart-figure {
  font-size: 1.125rem;
  font-weight: 600;
  color: #172b4d;
  line-height: 1.375rem;
  text-align: left;
}
.ohw-modal-inner .chart-modal-inner .chart-figure span.loss {
  color: #ff0000;
}
.ohw-modal-inner .chart-modal-inner .chart-figure span.profit {
  color: #4bde97;
}
.ohw-modal-inner .chart-modal-inner .ohw-checkbox-container {
  margin-left: 2rem;
}
.ohw-modal-inner .chart-modal-inner .general-stats {
  height: auto;
  min-height: 15rem;
}
.ohw-modal-inner .gamification {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 20rem;
  height: 20rem;
}
.ohw-modal-inner .gamification-products {
  margin-right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ohw-modal-inner .gamification-products-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 10rem;
  flex: 1;
  margin: 0.1rem 0;
  background-color: #ff0000;
  font-size: 0.875rem;
  color: #ffffff;
  font-weight: 600;
}
.ohw-modal-inner .gamification-products-item.active {
  opacity: 1;
}
.ohw-modal-inner .gamification-products-item.disabled {
  opacity: 0.2;
}
.ohw-modal-inner .gamification-progress {
  height: 100%;
  width: auto;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.ohw-modal-inner .gamification-progress-loader {
  width: 1rem;
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
  border-radius: 2rem; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */ /* Firefox 3.10 - 3.6 */
  box-shadow: 0px 1px 10px #f6f6f6;
}
.ohw-modal-inner .gamification-progress-loader-inner {
  width: 100%;
  transition: height 1s ease;
}

.progress-loader {
  width: 100%;
  height: 0.25rem;
  position: fixed;
  top: 0;
  left: 0;
}
.progress-loader-inner {
  height: 100%;
  background-color: #2f49d1;
  transition: width 0.5s ease;
}

.search-query {
  width: 16.625rem;
  height: 2.75rem;
  background-color: #f0f2fa;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.search-query-input {
  flex: 4;
  width: 100%;
  max-width: 13.304rem;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 0.5rem;
  color: #888787;
}
.search-query-icon {
  flex: 1;
  width: 100%;
  max-width: 3.321rem;
  color: #2f49d1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.form-field,
.react-datepicker-wrapper {
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.form-field-label,
.form-field .date-picker-label,
.react-datepicker-wrapper-label,
.react-datepicker-wrapper .date-picker-label {
  color: #888787;
  line-height: 1.5rem;
  margin: 0.5rem 0;
}
.form-field-label i.fa.fa-asterisk,
.form-field .date-picker-label i.fa.fa-asterisk,
.react-datepicker-wrapper-label i.fa.fa-asterisk,
.react-datepicker-wrapper .date-picker-label i.fa.fa-asterisk {
  color: #ff0000;
  font-size: 0.75rem;
}
.form-field p.date-picker-label,
.react-datepicker-wrapper p.date-picker-label {
  margin-bottom: 0;
}
.form-field-input,
.form-field input,
.react-datepicker-wrapper-input,
.react-datepicker-wrapper input {
  width: 100%;
  height: 2.75rem;
  background-color: #ffffff;
  padding: 0 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #d0d0d0;
  color: #2f49d1;
  outline: none;
}
.form-field .form-field-input.formatted,
.react-datepicker-wrapper .form-field-input.formatted {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.form-field .error-message,
.react-datepicker-wrapper .error-message {
  color: #ff0000;
  font-size: 0.875rem;
}
.form-field textarea,
.react-datepicker-wrapper textarea {
  height: 100% !important;
  padding: 0.5rem;
}
.form-field select,
.react-datepicker-wrapper select {
  -webkit-appearance: none;
  appearance: none;
  background: url(../images/arrow_down.png) no-repeat right center;
  background-size: 0.875rem;
}

.ohw-radio-container .radio-message {
  margin: 0 0.5rem;
}

.demo-wrapper a {
  color: #172b4d;
  font-weight: 600;
  text-decoration: none;
}
.demo-wrapper .demo-editor {
  width: 100%;
  min-height: 10rem;
  background-color: #ffffff;
  border: 1px solid #b6b6b6;
  border-radius: 0.187rem;
  padding: 0.5rem;
}

.ohw-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 0.875rem !important;
  -webkit-user-select: none;
  user-select: none;
  max-width: 10rem;
}
.ohw-checkbox-container .checkbox-message {
  color: #2f49d1;
  font-weight: 600;
  margin-left: 1.5rem;
}
.ohw-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ohw-checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #2f49d1;
}
.ohw-checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}
.ohw-checkbox-container .checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #f0f2fa;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.ohw-checkbox-container .checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.ohw-checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #2f49d1;
}

form h2.form-header {
  font-size: 1rem;
  text-align: left;
  line-height: 1.5rem;
  margin: 0;
  color: #172b4d;
  font-weight: 600;
}
form h2.form-header i.fa {
  font-size: 0.75rem;
}

.app-btn,
.gamification-btn,
.change-country {
  background-color: #2f49d1;
  color: #ffffff;
  border-radius: 0.187rem;
  min-width: 5.25rem;
  height: 2.25rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
  transition: transform 0.5s ease;
}
.app-btn i.fa,
.gamification-btn i.fa,
.change-country i.fa {
  margin-right: 0.3rem;
}
.app-btn:hover,
.gamification-btn:hover,
.change-country:hover {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
}
.app-btn:disabled,
.gamification-btn:disabled,
.change-country:disabled,
.disabled.app-btn,
.disabled.gamification-btn,
.disabled.change-country {
  opacity: 0.5;
}
.active.app-btn,
.active.gamification-btn,
.active.change-country {
  opacity: 1;
}

.gamification-btn {
  background-color: #ffffff;
  color: #2f49d1;
}

.change-country {
  background-color: transparent;
  color: #2f49d1;
  height: auto;
}

.close-btn {
  background-color: #ff0000;
  margin-left: 0.5rem;
}

.loading-button {
  font-weight: 600;
  font-size: 0.875rem;
  color: #2f49d1;
  margin: 1rem 0;
}
.loading-button span {
  margin-left: 0.5rem;
}

@media screen and (max-width: 68rem) {
  .login-inner {
    width: 80% !important;
    max-width: 20rem;
  }
  .login-inner-title {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }
}
#breadcrumb {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
#breadcrumb li {
  position: relative;
  float: left;
  width: 12.5%;
  cursor: pointer;
  transition: all 0.5s ease;
}
#breadcrumb li.active {
  opacity: 1;
}
#breadcrumb li.inactive {
  opacity: 0.5;
}
#breadcrumb li:hover {
  opacity: 1;
}
#breadcrumb li:first-child .arrowLeft {
  padding-left: 15px;
  border: none;
}
/* #breadcrumb li:last-child .arrowRight {
  padding-right: 15px;
  margin-right: 0;
  border: none;
} */
#breadcrumb li p {
  color: #fff;
  text-decoration: none;
  position: relative;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 10px 0 5px;
  text-align: center;
  margin: 0 1.45rem 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
#breadcrumb li p i.fa {
  display: none;
}
@media screen and (max-width: 48rem) {
  #breadcrumb li p i.fa {
    display: block;
  }
  #breadcrumb li p span {
    display: none;
  }
}
#breadcrumb li .arrowLeft,
#breadcrumb li .arrowRight {
  position: absolute;
  top: 0;
  border: 0 solid #2f49d1;
  border-width: 20px 10px;
  width: 0;
  height: 0;
}
#breadcrumb li .arrowLeft {
  left: -20px;
  border-left-color: transparent;
}
#breadcrumb li .arrowRight {
  right: 3.5px;
  border-color: transparent;
  border-left-color: #2f49d1;
}
#breadcrumb li:active {
  background-color: #16a085;
}
#breadcrumb li:active .arrowLeft {
  border-color: #16a085;
  border-left-color: transparent;
}
#breadcrumb li:active .arrowRight {
  border-left-color: #16a085;
}

.select-country-container {
  height: 100vh;
  width: 100vw;
  background-color: #f0f2fa;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.select-country-container-inner {
  width: 80%;
  height: 23.5rem;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.select-country-container-inner .left-pane {
  flex: 1;
  max-width: 40%;
  margin-right: 2rem;
  text-align: left;
}
.select-country-container-inner .left-pane h2 {
  font-weight: 600;
  font-size: 2rem;
}
.select-country-container-inner .left-pane p {
  font-size: 1.1rem;
  font-weight: 400;
}
.select-country-container-inner .right-pane {
  flex: 2;
  min-width: 60%;
  height: auto;
}
.select-country-container-inner .right-pane .country-item {
  background-color: #172b4d;
  border-radius: 0.375rem;
  height: 100%;
  padding: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.select-country-container-inner .right-pane .country-item-top {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.select-country-container-inner .right-pane .country-item-top img {
  width: 4.5rem;
  height: 3.375rem;
  object-fit: contain;
}
.select-country-container-inner .right-pane .country-item-top p.label {
  color: #ffffff;
  margin: 0.5rem 0 0 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 400;
}
.select-country-container-inner .right-pane .country-item-bottom {
  margin-top: 1rem;
  width: 100%;
}
.select-country-container-inner .right-pane .country-item-bottom-inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.select-country-container-inner .right-pane .country-item-bottom-inner-left h3 {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
}
.select-country-container-inner
  .right-pane
  .country-item-bottom-inner-right
  button {
  background-color: #ffffff;
  color: #172b4d;
  border: 1px solid #ffffff;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.25rem 1rem;
  border-radius: 0.175rem;
}
@media screen and (max-width: 48rem) {
  .select-country-container-inner {
    height: auto;
    max-height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .select-country-container-inner .left-pane {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .select-country-container-inner .right-pane {
    min-width: auto;
    width: 100%;
  }
  .select-country-container-inner .right-pane .country-item {
    margin-right: 0.15rem;
  }
}

table {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
}
@media only screen and (max-width: 760px),
  (min-device-width: 760px) and (max-device-width: 760px) {
  table {
    display: block;
    /* Hide table headers (but not display: none;, for accessibility) */
  }
  table thead,
  table tbody,
  table th,
  table td,
  table tr {
    display: block;
  }
  table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table td {
    height: 5rem;
    border: none;
    border-bottom: 1px solid #a5a6f6;
    position: relative;
    text-align: right;
    padding-left: 50%;
  }
  table td button,
  table td .product-color {
    margin-top: 0.3rem;
    float: right;
  }
  table td button:after,
  table td .product-color:after {
    clear: both;
    display: table;
    content: "";
  }
  table td:before {
    content: attr(data-label);
    float: left;
    white-space: nowrap;
    font-weight: bold;
    text-transform: uppercase;
  }
}
table a {
  color: #172b4d;
}
table td {
  color: #172b4d;
  font-weight: 400;
  margin: 0 auto;
  padding: 1.5rem 0.5rem;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  transition: background-color 0.5s ease;
}
table td .product-color {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
}
table td .product-color-display {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}
table th {
  color: #888787;
  padding: 1rem 0.5rem;
  border-bottom: 2px solid #d0d0d0;
  background-color: #f0f2fa;
}
table tbody tr {
  padding-top: 1rem;
  background-color: #f0f2fa;
}
table tbody tr:hover td {
  background-color: #f0f2fa !important;
}
table tbody .hospital-status {
  border-radius: 10000px;
  padding: 1px 10px;
}
table tbody .hospital-status.active,
.hospital-status.pre-active {
  background-color: rgb(44, 180, 225);
  color: white;
}
table tbody .hospital-status.newly-active {
  background-color: crimson;
  color: white;
}
table tbody .hospital-status.danger {
  background-color: lightcoral;
}
table tbody .hospital-status.add-value {
  background-color: coral;
}
table tbody .hospital-status.check-in {
  background-color: sandybrown;
}
table tbody .hospital-status.up-sell {
  background-color: lightgreen;
}
table tbody .hospital-status.loyal {
  background-color: violet;
}
table tbody button.status {
  width: 5.187rem;
  height: 1.25rem;
  border-radius: 1rem;
  border: none;
  background-color: #b6b6b6;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: #ffffff !important;
}
table tbody button.status.error {
  background-color: #ff0000;
}
table tbody button.status.view {
  background-color: #b6b6b6;
}
table tbody button.status.primary {
  background-color: #2f49d1;
}
table tbody button.status.new,
table tbody button.status.assign {
  background-color: #424200;
}
table tbody button.status.prospect {
  background-color: #767601;
}
table tbody button.status.pitch {
  background-color: #441ab7;
}
table tbody button.status.close {
  background-color: #b71C1C;
}
table tbody button.status.onboard {
  background-color: #424200;
}
table tbody button.status.preexodus {
  background-color: #09A5C5;
}
table tbody button.status.exodus {
  background-color: #663300;
}
table tbody button.status.active {
  background-color: #114b29;
}
table tbody button.status.inactive {
  background-color: #b71c1c;
}
table tbody button.status.hibernation,
table tbody button.status.hibernate {
  background-color: #4d8c5e;
}

.dashboard {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard .sb-pagination-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1.5rem;
}
.dashboard .sb-pagination-container #ohw-pagination {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
}
.dashboard
  .sb-pagination-container
  #ohw-pagination
  .ohw-page-item
  .ohw-page-link {
  border: none;
  background-color: #ffffff;
  width: 2rem;
  height: 2rem;
  border-radius: 0.4rem;
  color: #2f49d1;
  margin-right: 0.5rem;
}
.dashboard
  .sb-pagination-container
  #ohw-pagination
  .ohw-page-item
  .ohw-page-link
  i.fa {
  font-size: 0.75rem;
}
.dashboard
  .sb-pagination-container
  #ohw-pagination
  .ohw-page-item.active-page
  .ohw-page-link {
  background-color: #2f49d1;
  color: #ffffff;
}
.dashboard-top {
  display: none;
}
@media screen and (max-width: 48rem) {
  .dashboard-top {
    display: block;
  }
}
.dashboard-top nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  display: block;
  width: 100%;
  height: 3rem;
  min-height: 0;
  min-width: 100%;
  padding: 0 1rem;
  background-color: #2f49d1;
  font-size: 0.82rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-top nav img.lb-logo-only {
  height: 1.5rem;
  width: auto;
  object-fit: contain;
}
.dashboard-top nav .responsive-toggle {
  cursor: pointer;
}
.dashboard-top nav .responsive-toggle .burger-line-top,
.dashboard-top nav .responsive-toggle .burger-line-middle,
.dashboard-top nav .responsive-toggle .burger-line-bottom {
  width: 1.5rem;
  height: 2px;
  margin: 0.3125rem 0;
  background-color: #ffffff;
}
.dashboard-top nav .responsive-toggle .burger-line-middle {
  width: 1.01rem;
}
.dashboard-top .mobile-menu {
  width: 20rem;
  position: fixed;
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;
  right: 0;
  top: 0;
  background-color: #2f49d1;
  z-index: 100;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  animation: expand-menu-animation 0.5s;
  -webkit-animation: expand-menu-animation 0.5s;
  -moz-animation: expand-menu-animation 0.5s;
  -o-animation: expand-menu-animation 0.5s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-top .mobile-menu::-webkit-scrollbar {
  display: none;
}
.dashboard-top .mobile-menu button.menu-toggle {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 1.25rem;
  z-index: 20;
}
.dashboard-top .mobile-menu ul.nav-items,
.dashboard-top .mobile-menu ul.sublist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.dashboard-top .mobile-menu ul.nav-items li.nav-item,
.dashboard-top .mobile-menu ul.sublist li.nav-item {
  width: 100%;
  height: 2rem;
  margin: 1rem 0;
  color: #f0f2fa;
  opacity: 0.6;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-top .mobile-menu ul.nav-items li.nav-item.active,
.dashboard-top .mobile-menu ul.nav-items li.nav-item:hover,
.dashboard-top .mobile-menu ul.sublist li.nav-item.active,
.dashboard-top .mobile-menu ul.sublist li.nav-item:hover {
  color: #ffffff;
  opacity: 1;
  border-right: 4px solid #ffffff;
}
.dashboard-top .mobile-menu ul.nav-items li.nav-item i.fa,
.dashboard-top .mobile-menu ul.sublist li.nav-item i.fa {
  width: 2rem;
  margin-left: 1rem;
}
.dashboard-top .mobile-menu ul.nav-items li.nav-item i.fa-chevron-down,
.dashboard-top .mobile-menu ul.sublist li.nav-item i.fa-chevron-down {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #f0f2fa;
  width: 1.3rem;
  height: 1.3rem;
  color: #2f49d1;
  border-radius: 1.5rem;
  margin: 0 0.5rem;
  font-size: 0.75rem;
}
.dashboard-top .mobile-menu ul.nav-items li.nav-item span,
.dashboard-top .mobile-menu ul.sublist li.nav-item span {
  flex: 1;
  text-align: left;
}
.dashboard-top .mobile-menu ul.nav-items li.nav-item div.complaints,
.dashboard-top .mobile-menu ul.sublist li.nav-item div.complaints {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #ff0000;
  color: #ffffff;
  width: 1.3rem;
  height: 1.3rem;
  font-size: 0.75rem;
  border-radius: 1.5rem;
  margin: 0 0.5rem;
}
.dashboard-top .mobile-menu ul.nav-items .hospitals,
.dashboard-top .mobile-menu ul.sublist .hospitals {
  list-style-type: none;
}
.dashboard-left {
  width: 15.8rem;
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;
  background-color: #2f49d1;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  transition: all 0.5s ease;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-left::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 48rem) {
  .dashboard-left {
    display: none;
  }
}
.dashboard-left.closed {
  width: 4.5rem;
}
.dashboard-left.closed img.lb-logo {
  display: none;
}
.dashboard-left.closed ul.nav-items li.nav-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.dashboard-left.closed ul.nav-items li.nav-item span {
  display: none;
}
.dashboard-left.closed ul.nav-items li.nav-item i.fa {
  font-size: 1.3rem;
  margin-left: 0;
}
.dashboard-left.closed ul.nav-items li.nav-item i.fa-chevron-down,
.dashboard-left.closed ul.nav-items li.nav-item i.fa-chevron-up {
  display: none;
}
.dashboard-left.closed ul.nav-items li.nav-item div.complaints {
  display: none;
}
.dashboard-left.closed button {
  width: 4.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-left.closed button span {
  display: none;
}
.dashboard-left.closed button i.fa {
  font-size: 1.5rem;
}
.dashboard-left-top {
  width: 100%;
}
.dashboard-left-top-header {
  height: 3.75rem;
  padding: 0.5rem;
  opacity: 0.6;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-left-top-header img.lb-logo,
.dashboard-left-top-header img.lb-logo-only {
  height: 2rem;
  width: 6.5rem;
  object-fit: contain;
}
.dashboard-left-top-header img.lb-logo-only {
  display: none;
  width: auto;
  margin-left: 1rem;
}
.dashboard-left-top ul.nav-items,
.dashboard-left-top ul.sublist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.dashboard-left-top ul.nav-items li.nav-item,
.dashboard-left-top ul.sublist li.nav-item {
  width: 100%;
  height: 2rem;
  margin: 1rem 0;
  color: #f0f2fa;
  opacity: 0.6;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-left-top ul.nav-items li.nav-item.active,
.dashboard-left-top ul.nav-items li.nav-item:hover,
.dashboard-left-top ul.sublist li.nav-item.active,
.dashboard-left-top ul.sublist li.nav-item:hover {
  color: #ffffff;
  opacity: 1;
  border-right: 4px solid #ffffff;
}
.dashboard-left-top ul.nav-items li.nav-item i.fa,
.dashboard-left-top ul.sublist li.nav-item i.fa {
  width: 2rem;
  margin-left: 1rem;
}
.dashboard-left-top ul.nav-items li.nav-item i.fa-chevron-down,
.dashboard-left-top ul.nav-items li.nav-item i.fa-chevron-up,
.dashboard-left-top ul.sublist li.nav-item i.fa-chevron-down,
.dashboard-left-top ul.sublist li.nav-item i.fa-chevron-up {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #f0f2fa;
  width: 1.3rem;
  height: 1.3rem;
  color: #2f49d1;
  border-radius: 1.5rem;
  margin: 0 0.5rem;
  font-size: 0.75rem;
}
.dashboard-left-top ul.nav-items li.nav-item span,
.dashboard-left-top ul.sublist li.nav-item span {
  flex: 1;
  text-align: left;
}
.dashboard-left-top ul.nav-items li.nav-item div.complaints,
.dashboard-left-top ul.sublist li.nav-item div.complaints {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #ff0000;
  color: #ffffff;
  width: 1.3rem;
  height: 1.3rem;
  font-size: 0.75rem;
  border-radius: 1.5rem;
  margin: 0 0.5rem;
}
.dashboard-left-top ul.nav-items .hospitals,
.dashboard-left-top ul.sublist .hospitals {
  list-style-type: none;
}
.dashboard-left-top button.menu-toggle {
  background-color: transparent;
  border: none;
  color: #ffffff;
}
.dashboard-left-top button.menu-toggle i.fa {
  font-size: 1.5rem;
}
.dashboard-left-bottom {
  width: 100%;
  margin-bottom: 1rem;
}
.dashboard-left-bottom button.sign-out {
  display: block;
  margin: 1rem 0;
  background-color: transparent;
  border: none;
  color: #f0f2fa;
  opacity: 0.6;
  font-size: 0.95rem;
  font-weight: 600;
}
.dashboard-left-bottom button.sign-out i.fa {
  width: 2rem;
}
.dashboard-right {
  width: 100%;
  flex: 3;
  height: 100%;
  max-height: 100vh;
  padding: 1rem;
  margin-left: 4.5rem;
}
@media screen and (max-width: 48rem) {
  .dashboard-right {
    margin-left: 0;
  }
}
.dashboard-right-body {
  width: 100%;
  height: auto;
}
.dashboard-right-body header {
  height: 5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-right-body header h1 {
  text-align: left;
  color: #172b4d;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.dashboard-right-body header .header-right-options {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right-body header .header-right-options button.toggle-city {
  height: auto;
  width: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0.175rem;
  color: #172b4d;
  font-weight: 900;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.dashboard-right-body
  header
  .header-right-options
  button.toggle-city.first-item {
  margin-right: 1rem;
}
.dashboard-right-body header .header-right-options button.toggle-city i.fa {
  margin-right: 0.3rem;
}
@media screen and (max-width: 48rem) {
  .dashboard-right-body header h1 {
    font-size: 1.1rem;
    line-height: 1rem;
    margin: 0;
  }
}
.dashboard-right-body .breakdown-item {
  background-color: #ffffff;
  height: 8.56rem;
  min-width: 16.88rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  flex: 1;
  padding: 1rem;
  transition: box-shadow 0.5s ease;
}
.dashboard-right-body .breakdown-item:hover {
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */ /* Firefox 3.10 - 3.6 */
  box-shadow: 0px 1px 10px gainsboro;
}
.dashboard-right-body .breakdown-item h2 {
  margin: 0 0 0.5rem 0;
  color: #b6b6b6;
  line-height: 1rem;
  font-size: 1.125rem;
  text-align: left;
}
.dashboard-right-body .breakdown-item-inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right-body .breakdown-item-inner-left {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background-color: gainsboro;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.dashboard-right-body .breakdown-item-inner-left i.fa {
  font-weight: 600;
}
.dashboard-right-body .breakdown-item-inner-right {
  text-align: left;
}
.dashboard-right-body .breakdown-item-inner-right h3.title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.31rem;
  margin: 0;
}
.dashboard-right-body .breakdown-item-inner-right p,
.dashboard-right-body .breakdown-item-inner-right .description {
  margin: 0.2rem 0;
  color: #b6b6b6;
  line-height: 1rem;
  font-size: 0.875rem;
}
.dashboard-right-body .breakdown-item-inner-right .bottom {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right-body .breakdown-item-inner-right .bottom .rate.increase {
  color: #4bde97;
}
.dashboard-right-body .breakdown-item-inner-right .bottom .rate.decrease {
  color: #ff0000;
}
.dashboard-right-body .breakdown-item-inner-right .bottom .rate i.fa {
  margin-right: 0.2rem;
}
.dashboard-right-body .breakdown-item-inner-right .bottom .description {
  margin-left: 0.2rem;
}
.dashboard-right-body .breakdown-item:last-child {
  margin-right: 0;
}
.dashboard-right-body .breakdown-item .mom-button-container {
  width: 100%;
  position: relative;
}
.dashboard-right-body .breakdown-item .mom-button-container button.mom {
  position: absolute;
  right: 0;
  color: #a5a6f6;
  font-weight: 900;
  text-decoration: underline;
  background-color: transparent;
  display: inline-block;
  border: none;
  width: auto;
}
.dashboard-right-body .top-row-breakdown {
  margin-bottom: 1rem;
  height: auto;
}
.dashboard-right-body .top-row-breakdown button.slick-prev:before,
.dashboard-right-body .top-row-breakdown button.slick-next:before {
  color: #2f49d1;
}
.dashboard-right-body .top-row-breakdown .monthly-unfulfilled,
.dashboard-right-body .top-row-breakdown .monthly-active-hospitals,
.dashboard-right-body .top-row-breakdown .monthly-onboarded-hospitals {
  margin-left: 1rem;
}
@media screen and (max-width: 64rem) {
  .dashboard-right-body .top-row-breakdown {
    margin-bottom: 2rem;
  }
  .dashboard-right-body .top-row-breakdown .monthly-total-hospitals {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 48rem) {
  .dashboard-right-body .top-row-breakdown .monthly-onboarded-hospitals,
  .dashboard-right-body .top-row-breakdown .monthly-total-hospitals {
    margin-left: 0;
  }
}
@media screen and (max-width: 34.5em) {
  .dashboard-right-body .top-row-breakdown .monthly-unfulfilled,
  .dashboard-right-body .top-row-breakdown .monthly-active-hospitals,
  .dashboard-right-body .top-row-breakdown .monthly-onboarded-hospitals {
    margin-left: 0;
  }
}
.dashboard-right-body
  .top-row-breakdown
  .monthly-active-hospitals
  .breakdown-item-inner-left {
  background-color: #d6dbf6;
  color: #2f49d1;
}
.dashboard-right-body
  .top-row-breakdown
  .monthly-onboarded-hospitals
  .breakdown-item-inner-left {
  background-color: #ccf6e2;
  color: #4bde97;
}
.dashboard-right-body
  .top-row-breakdown
  .monthly-unfulfilled
  .breakdown-item-inner-left {
  background-color: #ffcccc;
  color: #ff0000;
}
.dashboard-right-body .chart-breakdown {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  height: 23.13rem;
}
.dashboard-right-body .chart-breakdown-left {
  min-width: 53rem;
  flex: 3;
  height: 100%;
  margin-right: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital {
  width: 100%;
  height: 100%;
  padding: 1rem;
  transition: box-shadow 0.5s ease;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital-slider {
  width: 100%;
  height: 100%;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-slider
  .slick-list {
  max-height: 100%;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-slider
  .slick-slide {
  background-color: #ffffff;
  border-radius: 0.5rem;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: 23rem;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-slider
  .slick-slide:last-child {
  margin-right: 0;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital:hover {
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */ /* Firefox 3.10 - 3.6 */
  box-shadow: 0px 1px 10px gainsboro;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital-top {
  padding: 0 0 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital-top-initials {
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 2.8rem;
  color: #ffffff;
  background-color: #172b4d;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital-top-details {
  flex: 1;
  margin-left: 1rem;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-top-details
  .hospital-status {
  background-color: #441ab7;
  color: #ffffff;
  display: inline-block;
  line-height: 1rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-top-details
  .hospital-name {
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-top-details
  .hospital-email {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  color: #888787;
}
.dashboard-right-body .chart-breakdown-left .assigned-hospital-bottom {
  height: 100%;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-bottom
  .details-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-bottom
  .details-item-icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  background-color: #f0f2fa;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right-body
  .chart-breakdown-left
  .assigned-hospital-bottom
  .details-item-value {
  flex: 1;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 1rem 0;
  border-top: 1px solid #d7dfe9;
  border-bottom: 1px solid #d7dfe9;
}
.dashboard-right-body .chart-breakdown-left .sales-chart {
  background-color: #ffffff;
  padding: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.dashboard-right-body .chart-breakdown-left .sales-chart h2 {
  text-align: left;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #172b4d;
  font-weight: 600;
}
.dashboard-right-body .chart-breakdown-left .sales-chart .sales-statistics {
  height: auto;
  min-height: 15rem;
  max-height: 17.13rem;
}
.dashboard-right-body
  .chart-breakdown-left
  .sales-chart
  .sales-statistics
  .chart-label {
  font-size: 0.875rem;
  color: #172b4d;
  text-align: left;
  line-height: 1rem;
  font-weight: 600;
}
.dashboard-right-body .chart-breakdown-right {
  flex: 1;
}
.dashboard-right-body .chart-breakdown-right .total-hospital-visits {
  margin-right: 0;
  width: 100%;
}
.dashboard-right-body
  .chart-breakdown-right
  .total-hospital-visits
  .breakdown-item-inner-left {
  background-color: #d6dbf6;
  color: #2f49d1;
}
.dashboard-right-body .chart-breakdown-right .bottom-chart {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #2f49d1;
  min-height: 13.357rem;
  height: 100%;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.dashboard-right-body .chart-breakdown-right .bottom-chart .chart-header {
  font-weight: 600;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-right-body
  .chart-breakdown-right
  .bottom-chart
  .chart-header
  .chart-header-title,
.dashboard-right-body
  .chart-breakdown-right
  .bottom-chart
  .chart-header
  .daily-average {
  text-align: left;
  font-size: 0.875rem;
}
.dashboard-right-body
  .chart-breakdown-right
  .bottom-chart
  .chart-header
  .daily-average {
  color: #f0f2fa;
  opacity: 0.6;
}
.dashboard-right-body
  .chart-breakdown-right
  .bottom-chart
  .chart-header
  .chart-header-title,
.dashboard-right-body
  .chart-breakdown-right
  .bottom-chart
  .chart-header
  .amount {
  color: #ffffff;
  font-size: 1rem;
}
.dashboard-right-body .chart-breakdown-right .bottom-chart .canvas-container {
  position: absolute;
  bottom: -0.2rem;
  right: -0.2rem;
  left: -0.2rem;
}
@media screen and (max-width: 48rem) {
  .dashboard-right-body .chart-breakdown {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
  .dashboard-right-body .chart-breakdown-left {
    display: none;
    flex: 1;
    height: auto;
    width: 100%;
    min-width: auto !important;
    margin-bottom: 1rem;
  }
  .dashboard-right-body .chart-breakdown-left .sales-statistics {
    height: auto;
    min-height: auto;
    max-height: auto;
    padding-bottom: 2.5rem;
  }
  .dashboard-right-body .chart-breakdown-right {
    width: 100%;
  }
}
.dashboard-right-body .bottom-row-breakdown {
  margin: 1rem 0;
  height: 23.13rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right-body .bottom-row-breakdown-left {
  min-width: 53rem;
  flex: 3;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem 0;
  margin-right: 1rem;
}
.dashboard-right-body .bottom-row-breakdown-left .activity-feed {
  min-height: auto;
}
.dashboard-right-body .bottom-row-breakdown-right {
  flex: 1;
  min-width: 16.88rem;
  height: 100%;
}
.dashboard-right-body .bottom-row-breakdown-right-inner {
  padding: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow-y: auto;
  background-color: #ffffff;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  transition: box-shadow 0.5s ease;
}
.dashboard-right-body .bottom-row-breakdown-right-inner::-webkit-scrollbar {
  display: none;
}
.dashboard-right-body .bottom-row-breakdown-right-inner:hover {
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */ /* Firefox 3.10 - 3.6 */
  box-shadow: 0px 1px 10px gainsboro;
}
.dashboard-right-body .bottom-row-breakdown-right-inner .top-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-right-body .bottom-row-breakdown-right-inner .top-header h2 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: #172b4d;
  margin: 0;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .top-header
  button.full-report {
  color: #2f49d1;
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 1rem;
  text-decoration: underline;
  background: transparent;
  border: none;
}
.dashboard-right-body .bottom-row-breakdown-right-inner .sales-paragraph {
  color: #b6b6b6;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 0.875rem;
  text-align: left;
  margin: 0.5rem 0 0 0;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leaderboard-category-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  margin: 0.5rem 0;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leaderboard-category-buttons::-webkit-scrollbar {
  display: none;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leaderboard-category-buttons
  .app-btn {
  margin-right: 0.5rem;
  width: auto;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leaderboard-category-buttons
  .app-btn:last-child {
  margin-right: 0;
}
.dashboard-right-body .bottom-row-breakdown-right-inner .leadership-board {
  width: 100%;
}
.dashboard-right-body .bottom-row-breakdown-right-inner .leadership-board-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  p {
  margin: 0.2rem 0;
  line-height: 1rem;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .left-item {
  flex: 2;
  text-align: left;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .left-item
  .title {
  font-size: 0.875rem;
  color: #172b4d;
  font-weight: 600;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .left-item
  .subtitle {
  font-size: 0.75rem;
  color: #b6b6b6;
  font-weight: 400;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .right-item {
  flex: 1;
  text-align: right;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .right-item
  .price {
  font-size: 0.875rem;
  color: #172b4d;
  font-weight: 600;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .right-item
  .inner {
  font-size: 0.75rem;
  color: #888787;
  font-weight: 600;
  border-radius: 0.3rem;
  display: inline-block;
  text-align: right;
  padding: 0.1rem 0.2rem;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .right-item
  .inner.success {
  background-color: #ccf6e2;
  color: #4bde97;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .right-item
  .inner.error {
  background-color: #ffe6e6;
  color: #ff0000;
}
.dashboard-right-body
  .bottom-row-breakdown-right-inner
  .leadership-board-item
  .right-item
  .inner.royal {
  background-color: #d3d4fb;
  color: #441ab7;
}
@media screen and (max-width: 48rem) {
  .dashboard-right-body .bottom-row-breakdown {
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-right-body .bottom-row-breakdown-left {
    flex: 1;
    min-width: 100%;
    margin-right: 0;
  }
  .dashboard-right-body .bottom-row-breakdown-right {
    flex: 1;
    min-width: 100%;
  }
  .dashboard-right-body .bottom-row-breakdown-right-inner {
    max-height: 23.13rem;
  }
}
.dashboard-right-body .submit-pitch-container {
  width: 100%;
  min-height: 40rem;
  box-sizing: border-box;
  border-radius: 0.375rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right-body .submit-pitch-container .submit-pitch-left {
  flex: 1;
  height: 100%;
  margin-right: 3rem;
  position: relative;
}
.dashboard-right-body
  .submit-pitch-container
  .submit-pitch-left
  .submit-pitch-item {
  border-left: 1px solid #b6b6b6;
  height: 100%;
  min-height: 5rem;
  width: 100%;
  padding: 0 0 0 2rem;
  position: relative;
}
.dashboard-right-body
  .submit-pitch-container
  .submit-pitch-left
  .submit-pitch-item
  h2 {
  font-size: 0.975rem;
  color: #172b4d;
  line-height: 1.5rem;
  margin: 0.5rem 0;
}
.dashboard-right-body
  .submit-pitch-container
  .submit-pitch-left
  .submit-pitch-item-inner {
  min-height: 5rem;
  background-color: #ffffff;
  font-size: 0.875rem;
  padding: 1rem;
  border-radius: 0.375rem;
}
.dashboard-right-body
  .submit-pitch-container
  .submit-pitch-left
  .submit-pitch-item-inner
  p {
  color: #888787;
  line-height: 1rem;
  margin: 0;
}
.dashboard-right-body
  .submit-pitch-container
  .submit-pitch-left
  .submit-pitch-item::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid #4bde97;
  display: block;
  text-align: center;
  line-height: 1.5rem;
  position: absolute;
  top: -0.75rem;
  left: -0.75rem;
  background: #f0f2fa;
  color: #4bde97;
  cursor: pointer;
}
.dashboard-right-body .submit-pitch-container .submit-pitch-right {
  height: 100%;
  flex: 1;
}
.dashboard-right-body .submit-pitch-container .submit-pitch-right-inner {
  background-color: #ffffff;
  padding: 1rem;
}
.dashboard-right-body .submit-pitch-container .submit-pitch-right-inner h3 {
  font-size: 1.2rem;
  color: #172b4d;
  line-height: 1.5rem;
  font-weight: 600;
}
.dashboard-right-body
  .submit-pitch-container
  .submit-pitch-right-inner
  p.subtitle {
  color: #888787;
  line-height: 1rem;
}
.dashboard-right-body .submit-pitch-container .submit-pitch-right .app-btn {
  width: auto;
  border: 1px solid #2f49d1;
  background-color: transparent;
  color: #2f49d1;
}
.dashboard-right .dashboard-footer {
  height: 5rem;
  border-top: 1px solid gainsboro;
  margin-top: 1rem;
  padding: 1rem;
  color: #888787;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-right .dashboard-footer a,
.dashboard-right .dashboard-footer button {
  text-decoration: none;
  color: #888787;
  margin-right: 1rem;
}
.dashboard-right .dashboard-footer button {
  border: none;
  background-color: transparent;
}
@media screen and (max-width: 33rem) {
  .dashboard-right .dashboard-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-right .dashboard-footer button.send-feedback {
    margin-right: 0;
  }
}
@media screen and (max-width: 19.75rem) {
  .dashboard-right .dashboard-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0.3rem;
  }
}
.dashboard-right .history-container {
  width: 100%;
  box-sizing: border-box;
  /* The actual timeline (the vertical ruler) */
  /* Media queries - Responsive timeline on screens less than 600px wide */
}
.dashboard-right .history-container .hospital-details {
  padding: 1.25rem 2rem;
  position: relative;
  height: auto;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right .history-container .hospital-details-inner {
  padding: 1rem;
  margin: 0 auto;
  background-color: #2f49d1;
  height: 100%;
  width: auto;
  display: inline-block;
  border-radius: 0.375rem;
  color: #ffffff;
  text-align: left;
}
.dashboard-right .history-container .hospital-details-inner i.fa {
  font-weight: 600;
  margin-right: 0.3rem;
}
.dashboard-right .history-container .hospital-details-inner h2 {
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: 600;
}
.dashboard-right .history-container .hospital-details-inner ul {
  margin: 0 auto;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right .history-container .hospital-details-inner ul li {
  margin-right: 1rem;
}
.dashboard-right
  .history-container
  .hospital-details-inner
  button.view-gamification {
  background-color: transparent;
  border: 1px solid #f0f2fa;
  color: #f0f2fa;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.dashboard-right
  .history-container
  .hospital-details-inner
  button.view-gamification:hover {
  transition: all 0.5s ease;
  animation: zoom-in 0.5s ease;
  -webkit-animation: zoom-in 0.5s ease;
  -moz-animation: zoom-in 0.5s ease;
  -o-animation: zoom-in 0.5s ease;
}
.dashboard-right .history-container .timeline {
  position: relative;
  width: auto;
  margin: 0 auto;
  /* The actual timeline (the vertical ruler) */
  /* Container around content */
}
.dashboard-right .history-container .timeline:after {
  content: "";
  position: absolute;
  width: 0.375rem;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -6px;
}
.dashboard-right .history-container .timeline .history-container-item {
  padding: 0 2.5rem;
  position: relative;
  width: 50%;
  color: #ffffff;
  /* Place the history-container-item to the left */
  /* Place the history-container-item to the right */
  /* The circles on the timeline */
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-left
  ul,
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-right
  ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.dashboard-right .history-container .timeline .history-container-item.hci-left {
  left: 0;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  /* Add arrows to the left history-container-item (pointing right) */
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-left
  ul {
  direction: rtl;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-left:before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
  z-index: 1;
  right: 1rem;
  border-width: 1rem 0 1rem 1rem;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-right {
  left: 50%;
  text-align: left;
  /* Add arrows to the right history-container-item (pointing left) */
  /* Fix the circle for history-container-items on the right side */
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-right:before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50%;
  width: 2rem;
  z-index: 1;
  left: 0.5rem;
  border-width: 1rem 1rem 1rem 0;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hci-right:after {
  left: -0.5rem;
}
.dashboard-right .history-container .timeline .history-container-item:after {
  content: "";
  position: absolute;
  width: 0.375rem;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.new.hci-left:before {
  border: medium solid #424200;
  border-color: transparent #424200 transparent transparent;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.new
  .history-container-item-content {
  color: #424200;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.new:after {
  background-color: #424200;
  border: 4px solid #424200;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.prospect.hci-right:before {
  border: medium solid #767601;
  border-color: transparent transparent transparent #767601;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.prospect
  .history-container-item-content {
  color: #767601;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.prospect:after {
  background-color: #767601;
  border: 4px solid #767601;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.pitch.hci-left:before {
  border: medium solid #441ab7;
  border-color: transparent #441ab7 transparent transparent;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.pitch
  .history-container-item-content {
  color: #441ab7;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.pitch:after {
  background-color: #441ab7;
  border: 4px solid #441ab7;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.onboard.hci-right:before {
  border: medium solid #424200;
  border-color: transparent transparent transparent #424200;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.onboard
  .history-container-item-content {
  color: #424200;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.onboard:after {
  background-color: #424200;
  border: 4px solid #424200;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.exodus.hci-left:before {
  border: medium solid #663300;
  border-color: transparent #663300 transparent transparent;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.exodus
  .history-container-item-content {
  color: #663300;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.exodus:after {
  background-color: #663300;
  border: 4px solid #663300;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.active.hci-right:before {
  border: medium solid #114b29;
  border-color: transparent transparent transparent #114b29;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.active
  .history-container-item-content {
  color: #114b29;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.active:after {
  background-color: #114b29;
  border: 4px solid #114b29;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.inactive.hci-left:before {
  border: medium solid #b71c1c;
  border-color: transparent #b71c1c transparent transparent;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.inactive
  .history-container-item-content {
  color: #b71c1c;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.inactive:after {
  background-color: #b71c1c;
  border: 4px solid #b71c1c;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hibernated.hci-right:before {
  border: medium solid #4d8c5e;
  border-color: transparent transparent transparent #4d8c5e;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hibernated
  .history-container-item-content {
  color: #4d8c5e;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item.hibernated:after {
  background-color: #4d8c5e;
  border: 4px solid #4d8c5e;
}
.dashboard-right .history-container .timeline .history-container-item-content {
  padding: 0 1rem;
  position: relative;
  border-radius: 0.375rem;
  min-height: 5rem;
  width: 100%;
}
.dashboard-right
  .history-container
  .timeline
  .history-container-item-content
  h2 {
  font-size: 0.875rem;
  font-weight: 600;
}
@media screen and (max-width: 48rem) {
  .dashboard-right .history-container {
    /* Place the timelime to the left */
    /* Full-width history-container-items */
    /* Make all right containers behave like the left ones */
  }
  .dashboard-right .history-container .hospital-details-inner {
    width: 100%;
  }
  .dashboard-right .history-container .hospital-details-inner ul {
    margin: 0 auto;
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-right .history-container .hospital-details-inner ul li {
    margin-right: 1rem;
  }
  .dashboard-right .history-container .timeline::after {
    left: 2rem;
  }
  .dashboard-right .history-container .history-container-item {
    width: 100% !important;
    /* Make sure that all arrows are pointing leftwards */
  }
  .dashboard-right .history-container .history-container-item-content {
    text-align: left;
    width: 100% !important;
  }
  .dashboard-right
    .history-container
    .history-container-item.new.hci-right:before {
    border: medium solid #424200;
    border-color: transparent #424200 transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.prospect.hci-left:before {
    border: medium solid #767601;
    border-color: transparent #767601 transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.pitch.hci-right:before {
    border: medium solid #441ab7;
    border-color: transparent #441ab7 transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.onboard.hci-left:before {
    border: medium solid #424200;
    border-color: transparent #424200 transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.exodus.hci-right:before {
    border: medium solid #663300;
    border-color: transparent #663300 transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.active.hci-left:before {
    border: medium solid #114b29;
    border-color: transparent #114b29 transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.inactive.hci-right:before {
    border: medium solid #b71c1c;
    border-color: transparent #b71c1c transparent transparent;
  }
  .dashboard-right
    .history-container
    .history-container-item.hibernated.hci-left:before {
    border: medium solid #4d8c5e;
    border-color: transparent #4d8c5e transparent transparent;
  }
  .dashboard-right .history-container .history-container-item:before {
    left: 0rem;
  }
  .dashboard-right .history-container .history-container-item.hci-left:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    left: 0;
  }
  .dashboard-right .history-container .hci-left:after {
    background-color: inherit !important;
    left: 1.5rem;
  }
  .dashboard-right .history-container .hci-left ul {
    direction: unset !important;
  }
  .dashboard-right .history-container .hci-right {
    left: 0 !important;
  }
  .dashboard-right .history-container .hci-right:after {
    left: 1.5rem !important;
  }
}
.dashboard-right .hospitals-container,
.dashboard-right .loading-background,
.dashboard-right .pipeline-container,
.dashboard-right .agents-table-container,
.dashboard-right .support-container,
.dashboard-right .score-card,
.dashboard-right .tutorial-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.375rem;
}
.dashboard-right .hospitals-container-top,
.dashboard-right .loading-background-top,
.dashboard-right .pipeline-container-top,
.dashboard-right .agents-table-container-top,
.dashboard-right .support-container-top,
.dashboard-right .score-card-top,
.dashboard-right .tutorial-container-top {
  padding: 0 1rem;
  height: 5.25rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-right .hospitals-container-top .left-options .app-btn,
.dashboard-right .hospitals-container-top .right-options .app-btn,
.dashboard-right .loading-background-top .left-options .app-btn,
.dashboard-right .loading-background-top .right-options .app-btn,
.dashboard-right .pipeline-container-top .left-options .app-btn,
.dashboard-right .pipeline-container-top .right-options .app-btn,
.dashboard-right .agents-table-container-top .left-options .app-btn,
.dashboard-right .agents-table-container-top .right-options .app-btn,
.dashboard-right .support-container-top .left-options .app-btn,
.dashboard-right .support-container-top .right-options .app-btn,
.dashboard-right .score-card-top .left-options .app-btn,
.dashboard-right .score-card-top .right-options .app-btn,
.dashboard-right .tutorial-container-top .left-options .app-btn,
.dashboard-right .tutorial-container-top .right-options .app-btn {
  margin-top: 0;
  height: 2.75rem;
  width: auto;
}
.dashboard-right .hospitals-container-top .left-options,
.dashboard-right .loading-background-top .left-options,
.dashboard-right .pipeline-container-top .left-options,
.dashboard-right .agents-table-container-top .left-options,
.dashboard-right .support-container-top .left-options,
.dashboard-right .score-card-top .left-options,
.dashboard-right .tutorial-container-top .left-options {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right .hospitals-container-top .left-options .app-btn,
.dashboard-right .loading-background-top .left-options .app-btn,
.dashboard-right .pipeline-container-top .left-options .app-btn,
.dashboard-right .agents-table-container-top .left-options .app-btn,
.dashboard-right .support-container-top .left-options .app-btn,
.dashboard-right .score-card-top .left-options .app-btn,
.dashboard-right .tutorial-container-top .left-options .app-btn {
  margin-left: 0.5rem;
  width: 3rem;
}
.dashboard-right .hospitals-container-top .right-options,
.dashboard-right .loading-background-top .right-options,
.dashboard-right .pipeline-container-top .right-options,
.dashboard-right .agents-table-container-top .right-options,
.dashboard-right .support-container-top .right-options,
.dashboard-right .score-card-top .right-options,
.dashboard-right .tutorial-container-top .right-options {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right .hospitals-container-top .right-options .form-field,
.dashboard-right .loading-background-top .right-options .form-field,
.dashboard-right .pipeline-container-top .right-options .form-field,
.dashboard-right .agents-table-container-top .right-options .form-field,
.dashboard-right .support-container-top .right-options .form-field,
.dashboard-right .score-card-top .right-options .form-field,
.dashboard-right .tutorial-container-top .right-options .form-field {
  margin-top: 0;
  margin-right: 1rem;
}
.dashboard-right .hospitals-container-top .right-options .form-field-input,
.dashboard-right .loading-background-top .right-options .form-field-input,
.dashboard-right .pipeline-container-top .right-options .form-field-input,
.dashboard-right .agents-table-container-top .right-options .form-field-input,
.dashboard-right .support-container-top .right-options .form-field-input,
.dashboard-right .score-card-top .right-options .form-field-input,
.dashboard-right .tutorial-container-top .right-options .form-field-input {
  width: 11.563rem;
}
@media screen and (max-width: 48rem) {
  .dashboard-right .hospitals-container-top,
  .dashboard-right .loading-background-top,
  .dashboard-right .pipeline-container-top,
  .dashboard-right .agents-table-container-top,
  .dashboard-right .support-container-top,
  .dashboard-right .score-card-top,
  .dashboard-right .tutorial-container-top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    padding: 1rem;
  }
  .dashboard-right .hospitals-container-top .right-options,
  .dashboard-right .loading-background-top .right-options,
  .dashboard-right .pipeline-container-top .right-options,
  .dashboard-right .agents-table-container-top .right-options,
  .dashboard-right .support-container-top .right-options,
  .dashboard-right .score-card-top .right-options,
  .dashboard-right .tutorial-container-top .right-options {
    width: 100% !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-right .hospitals-container-top .right-options .form-field,
  .dashboard-right .loading-background-top .right-options .form-field,
  .dashboard-right .pipeline-container-top .right-options .form-field,
  .dashboard-right .agents-table-container-top .right-options .form-field,
  .dashboard-right .support-container-top .right-options .form-field,
  .dashboard-right .score-card-top .right-options .form-field,
  .dashboard-right .tutorial-container-top .right-options .form-field {
    margin-top: 0.5rem;
  }
  .dashboard-right .hospitals-container-top .right-options .form-field-input,
  .dashboard-right .loading-background-top .right-options .form-field-input,
  .dashboard-right .pipeline-container-top .right-options .form-field-input,
  .dashboard-right .agents-table-container-top .right-options .form-field-input,
  .dashboard-right .support-container-top .right-options .form-field-input,
  .dashboard-right .score-card-top .right-options .form-field-input,
  .dashboard-right .tutorial-container-top .right-options .form-field-input {
    width: 13rem;
  }
  .dashboard-right .hospitals-container-top .right-options .app-btn,
  .dashboard-right .loading-background-top .right-options .app-btn,
  .dashboard-right .pipeline-container-top .right-options .app-btn,
  .dashboard-right .agents-table-container-top .right-options .app-btn,
  .dashboard-right .support-container-top .right-options .app-btn,
  .dashboard-right .score-card-top .right-options .app-btn,
  .dashboard-right .tutorial-container-top .right-options .app-btn {
    margin-top: 0.5rem;
  }
}
.dashboard-right .hospitals-container-bottom-inner,
.dashboard-right .loading-background-bottom-inner,
.dashboard-right .pipeline-container-bottom-inner,
.dashboard-right .agents-table-container-bottom-inner,
.dashboard-right .support-container-bottom-inner,
.dashboard-right .score-card-bottom-inner,
.dashboard-right .tutorial-container-bottom-inner {
  padding: 0.5rem;
}
.dashboard-right .hospitals-container-bottom-inner:after,
.dashboard-right .loading-background-bottom-inner:after,
.dashboard-right .pipeline-container-bottom-inner:after,
.dashboard-right .agents-table-container-bottom-inner:after,
.dashboard-right .support-container-bottom-inner:after,
.dashboard-right .score-card-bottom-inner:after,
.dashboard-right .tutorial-container-bottom-inner:after {
  clear: both;
  display: table;
  content: "";
}
.dashboard-right .hospitals-container-bottom-inner .single-pipeline-item,
.dashboard-right .loading-background-bottom-inner .single-pipeline-item,
.dashboard-right .pipeline-container-bottom-inner .single-pipeline-item,
.dashboard-right .agents-table-container-bottom-inner .single-pipeline-item,
.dashboard-right .support-container-bottom-inner .single-pipeline-item,
.dashboard-right .score-card-bottom-inner .single-pipeline-item,
.dashboard-right .tutorial-container-bottom-inner .single-pipeline-item {
  float: left;
  width: 16rem;
  /* height: 6.06rem; */
  border: 1px solid #b6b6b6;
  padding: 0.5rem;
  text-align: left;
  margin: 0.5rem 0 0 0.5rem;
}
.dashboard-right .hospitals-container-bottom-inner .single-pipeline-item-title,
.dashboard-right .loading-background-bottom-inner .single-pipeline-item-title,
.dashboard-right .pipeline-container-bottom-inner .single-pipeline-item-title,
.dashboard-right
  .agents-table-container-bottom-inner
  .single-pipeline-item-title,
.dashboard-right .support-container-bottom-inner .single-pipeline-item-title,
.dashboard-right .score-card-bottom-inner .single-pipeline-item-title,
.dashboard-right .tutorial-container-bottom-inner .single-pipeline-item-title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #888787;
  text-transform: capitalize;
}
.dashboard-right
  .hospitals-container-bottom-inner
  .single-pipeline-item-subtitle,
.dashboard-right
  .loading-background-bottom-inner
  .single-pipeline-item-subtitle,
.dashboard-right
  .pipeline-container-bottom-inner
  .single-pipeline-item-subtitle,
.dashboard-right
  .agents-table-container-bottom-inner
  .single-pipeline-item-subtitle,
.dashboard-right .support-container-bottom-inner .single-pipeline-item-subtitle,
.dashboard-right .score-card-bottom-inner .single-pipeline-item-subtitle,
.dashboard-right
  .tutorial-container-bottom-inner
  .single-pipeline-item-subtitle {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0.5rem 0;
  color: #888787;
}
.dashboard-right
  .hospitals-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .hospitals-container-bottom-inner
  .single-pipeline-item-subtitle
  span,
.dashboard-right
  .loading-background-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .loading-background-bottom-inner
  .single-pipeline-item-subtitle
  span,
.dashboard-right
  .pipeline-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .pipeline-container-bottom-inner
  .single-pipeline-item-subtitle
  span,
.dashboard-right
  .agents-table-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .agents-table-container-bottom-inner
  .single-pipeline-item-subtitle
  span,
.dashboard-right
  .support-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .support-container-bottom-inner
  .single-pipeline-item-subtitle
  span,
.dashboard-right .score-card-bottom-inner .single-pipeline-item-subtitle a,
.dashboard-right .score-card-bottom-inner .single-pipeline-item-subtitle span,
.dashboard-right
  .tutorial-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .tutorial-container-bottom-inner
  .single-pipeline-item-subtitle
  span {
  font-weight: 600;
}
.dashboard-right
  .hospitals-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .loading-background-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .pipeline-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .agents-table-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right
  .support-container-bottom-inner
  .single-pipeline-item-subtitle
  a,
.dashboard-right .score-card-bottom-inner .single-pipeline-item-subtitle a,
.dashboard-right
  .tutorial-container-bottom-inner
  .single-pipeline-item-subtitle
  a {
  color: #888787;
}
.dashboard-right .loading-background {
  padding: 1rem;
  margin: 0 auto;
  text-align: center;
}
.dashboard-right .support-container {
  padding: 2rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
}
.dashboard-right .support-container-item {
  min-width: 20.5rem;
  width: 31%;
  height: 13.45rem;
  background-color: #172b4d;
  margin-right: 1rem;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  float: left;
}
@media screen and (max-width: 48rem) {
  .dashboard-right .support-container-item {
    width: 100%;
  }
}
.dashboard-right .support-container-item-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dashboard-right .support-container-item-inner .inner-container {
  text-align: left;
}
.dashboard-right .support-container-item-inner .inner-container h4 {
  font-size: 1.05rem;
  line-height: 1.5rem;
  font-weight: 900;
  margin: 0;
  text-align: left;
  color: #172b4d;
}
.dashboard-right .support-container-item-inner .inner-container p {
  font-size: 0.975rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #b6b6b6;
  border-bottom: 1px solid #e9e9e9;
  padding: 0.8rem 0;
  text-align: left;
}
.dashboard-right
  .support-container-item-inner
  .inner-container
  div.download-link {
  border: 1px solid #e9e9e9;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}
.dashboard-right
  .support-container-item-inner
  .inner-container
  div.download-link
  i.fa {
  color: #b6b6b6;
}
.dashboard-right
  .support-container-item-inner
  .inner-container
  div.download-link
  a,
.dashboard-right
  .support-container-item-inner
  .inner-container
  div.download-link
  button {
  margin-right: 0.3rem;
  color: dodgerblue;
  text-decoration: none;
}
.dashboard-right
  .support-container-item-inner
  .inner-container
  div.download-link
  button {
  border: none;
  background: transparent;
}
.dashboard-right .prospect-buttons {
  min-height: 5rem;
}
.dashboard-right .leads-arena-buttons,
.dashboard-right .prospect-buttons {
  width: 100%;
  display: block;
  min-height: 3rem;
  margin-bottom: 2rem;
}
.dashboard-right .leads-arena-buttons:after,
.dashboard-right .prospect-buttons:after {
  clear: both;
  display: table;
  content: "";
}
.dashboard-right .leads-arena-buttons .app-btn,
.dashboard-right .prospect-buttons .app-btn {
  width: auto;
  float: left;
  margin-right: 1rem;
}
.dashboard-right .leads-arena-buttons .app-btn.inactive,
.dashboard-right .prospect-buttons .app-btn.inactive {
  opacity: 0.5;
}
.dashboard-right .leads-arena-buttons .app-btn.active,
.dashboard-right .prospect-buttons .app-btn.active {
  opacity: 1;
}
.dashboard-right .activity-feed,
.dashboard-right .pipeline-container,
.dashboard-right .add-hospital {
  height: 100%;
  min-height: 40rem;
  text-align: center;
}
.dashboard-right .add-hospital {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 0.5rem;
}
.dashboard-right .current-revenue {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 1rem;
  overflow-y: auto;
}
.dashboard-right .current-revenue-header {
  font-size: 1.2rem;
  line-height: 1rem;
  font-weight: 600;
  color: #172b4d;
}
.dashboard-right .current-revenue-chart {
  height: auto;
  min-height: 15rem;
  max-height: 17.13rem;
}
.dashboard-right .current-revenue-chart canvas {
  height: auto;
  min-height: 15rem;
  max-height: 17.13rem;
}
.dashboard-right .current-revenue .highest-cities {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (max-width: 48rem) {
  .dashboard-right .current-revenue .highest-cities {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dashboard-right .current-revenue .highest-cities-data-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
.dashboard-right .current-revenue .highest-cities-header {
  font-size: 0.875rem;
  font-weight: 600;
  margin-right: 0.5rem;
}
.dashboard-right .current-revenue .highest-cities-data {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.dashboard-right .current-revenue .highest-cities-data-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-right: 0.5rem;
}
.dashboard-right .current-revenue .highest-cities-data-item-rank {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1rem;
  margin-right: 0.3rem;
}
.dashboard-right .current-revenue .highest-cities-data-item-value {
  font-size: 0.875rem;
  font-weight: 600;
}
.dashboard-right .score-card {
  min-height: 40rem;
  padding: 2rem;
}
.dashboard-right .score-card .container-fluid {
  padding: 0;
}
.dashboard-right .score-card .container-fluid .row {
  padding: 1.5rem 0;
  border: 1px solid #d7dfe9;
  border-radius: 0.375rem;
  margin: 0.5rem 0;
}
.dashboard-right .score-card-title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #172b4d;
}
.dashboard-right .score-card-title span {
  color: #ff0000;
  text-transform: capitalize;
}
.dashboard-right .score-card-item {
  color: #172b4d;
  margin: 0.5rem 0;
}
.dashboard-right .score-card-item-label {
  margin-bottom: 0.5rem;
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #888787;
}
.dashboard-right .score-card-item-value {
  width: 100%;
  height: 2.5rem;
  background-color: #ffffff;
  border: 1px dashed #d7dfe9;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
}
.dashboard-right .score-card-item-value-progress {
  width: 100%;
  min-height: 2.5rem;
  border: 1px dashed #d7dfe9;
  padding: 1rem;
  border-radius: 0.375rem;
}
.dashboard-right .score-card-item-value-progress-bar {
  width: 100%;
  height: 0.25rem;
  background-color: #d7dfe9;
  border-radius: 5rem;
  overflow: hidden;
}
.dashboard-right .score-card-item-value-progress-bar-inner {
  height: 0.25rem;
  background-color: #2f49d1;
  width: 60%;
}
.dashboard-right .score-card-item-value-progress .total-score {
  margin-top: 0.25rem;
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: 900;
  text-transform: uppercase;
}
.dashboard .no-items {
  background-color: #f0f2fa;
  padding: 1rem;
  color: #172b4d;
  font-weight: 600;
  border-radius: 0.3rem;
  width: auto;
  display: inline-block;
}
.dashboard .no-items p {
  margin: 0 auto;
  display: inline-block;
}
.dashboard .error {
  color: #ff0000;
}
.dashboard .primary {
  color: #2f49d1;
}
.dashboard .neutral {
  color: #888787;
}

.login,
.not-available {
  width: 100%;
  height: 100vh;
  background-color: #f0f2fa;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.login-inner,
.not-available-inner {
  width: 26.13rem;
  height: 31.13rem;
}
.login-inner-title,
.not-available-inner-title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #172b4d;
}
.login-inner-subtitle,
.not-available-inner-subtitle {
  font-weight: 400;
  color: #888787;
  font-size: 1rem;
  text-align: center;
  line-height: 1.5rem;
}
.login-inner .form-container,
.not-available-inner .form-container {
  width: 100%;
  height: auto;
  max-height: 25.5rem;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 2rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.login-inner .form-container::-webkit-scrollbar,
.not-available-inner .form-container::-webkit-scrollbar {
  display: none;
}
.login-inner .form-container .form-field-input,
.not-available-inner .form-container .form-field-input {
  background-color: #f0f2fa;
  border: none;
}
.login-inner .form-container .forgot-password,
.not-available-inner .form-container .forgot-password {
  margin: 1rem 0 0 0;
  text-align: left;
  width: 100%;
}
.login-inner .form-container .forgot-password-link,
.not-available-inner .form-container .forgot-password-link {
  margin-right: auto;
  text-decoration: none;
  font-weight: 600;
  color: #2f49d1;
  font-size: 0.875rem;
}
.login-inner .form-container .app-btn,
.not-available-inner .form-container .app-btn {
  width: 100%;
}
.login-inner .download-apk,
.not-available-inner .download-apk {
  margin-top: 2rem;
  text-align: center;
}
.login-inner .download-apk p,
.not-available-inner .download-apk p {
  color: #172b4d;
  line-height: 1.3rem;
  font-size: 1rem;
  font-weight: 600;
}
.login-inner .download-apk p a,
.not-available-inner .download-apk p a {
  margin-left: 0.3rem;
  color: #2f49d1;
  text-decoration: none;
}

.offline-notice {
  z-index: 200;
  background-color: #ff0000;
  color: #f0f2fa;
  height: 2.5rem;
  width: 20rem;
  font-weight: 600;
  border-radius: 0.375rem;
  position: fixed;
  left: 50%;
  top: 5%;
  transition: opacity 5s ease;
  transform: translate(-50%, -5%);
  -webkit-transform: translate(-50%, -5%);
  -moz-transform: translate(-50%, -5%);
  -o-transform: translate(-50%, -5%);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.offline-notice.online {
  background-color: #4bde97;
  opacity: 0;
}
.offline-notice i.fa {
  margin-right: 0.3rem;
}

.hospital_orders {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.hospital_orders_body {
  width: 65%;
  height: 100%;
  max-height: 85%;
  overflow-y: scroll;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 5px solid #2f49d1;
}
.hospital_orders_body h4 {
  text-align: left;
  color: #172b4d;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 0 0 1rem 0;
  font-weight: 600;
}
.hospital_orders_body .order-buttons {
  margin: 1rem 0;
}
.hospital_orders_body .order-buttons-item {
  background-color: transparent;
  border: none;
  border-bottom: 3px solid #2f49d1;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
  color: #2f49d1;
  width: 6rem;
  font-size: 0.75rem;
}
.hospital_orders_cadre {
  padding: 1.5rem;
  border: 1px solid #f7f7f7;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
}
.hospital_orders_cadre:nth-child(2) {
  margin: 0 1rem;
}
.hospital_orders_cadre_icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  align-self: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
  background-color: #eeeeee;
  color: #2f49d1;
}
.hospital_orders_cadre_icon i {
  font-size: 1.5rem;
}
.hospital_orders_cadre_label {
  margin: 1rem 0;
  font-size: 0.75rem;
  color: #172b4d;
  font-weight: 600;
  text-transform: uppercase;
}
.hospital_orders_cadre_value {
  padding: 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #2f49d1;
  color: #ffffff;
  border: 0.5rem;
  border-radius: 0.5rem;
} /*# sourceMappingURL=app.css.map */
