@import "./config";
@import "./mixins";
@import "./utilities";

$green: #1abc9c;
$green-darken: #16a085;

#breadcrumb {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    float: left;
    width: calc(100% / 8);
    cursor: pointer;
    @include transition(all 0.5s ease);

    &.active {
      opacity: 1;
    }

    &.inactive {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }

    &:nth-child(1) {
      p {
        background-color: #424200;

        &:before {
          border-color: #424200;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #424200;
        }
      }
    }
    &:nth-child(2) {
      p {
        background-color: #767601;

        &:before {
          border-color: #767601;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #767601;
        }
      }
    }
    &:nth-child(3) {
      p {
        background-color: #441ab7;

        &:before {
          border-color: #441ab7;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #441ab7;
        }
      }
    }
    &:nth-child(4) {
      p {
        background-color: #424200;

        &:before {
          border-color: #424200;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #424200;
        }
      }
    }
    &:nth-child(5) {
      p {
        background-color: #663300;

        &:before {
          border-color: #663300;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #663300;
        }
      }
    }
    &:nth-child(6) {
      p {
        background-color: #114b29;

        &:before {
          border-color: #114b29;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #114b29;
        }
      }
    }
    &:nth-child(7) {
      p {
        background-color: #b71c1c;

        &:before {
          border-color: #b71c1c;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #b71c1c;
        }
      }
    }
    &:nth-child(8) {
      p {
        background-color: #4d8c5e;

        &:before {
          border-color: #4d8c5e;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: #4d8c5e;
        }
      }
    }

    &:first-child {
      p {
        padding-left: 15px;
        &:before {
          border: none;
        }
      }
    }
    &:last-child {
      p {
        padding-right: 15px;
        margin-right: 0;
        // border-radius: 0 4px 4px 0;
        &:after {
          border: none;
        }
      }
    }

    p {
      color: #fff;
      text-decoration: none;
      position: relative;
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 10px 0 5px;
      text-align: center;
      margin: 0 1.45rem 0 0;
      @include flexCenter(row, center);

      i.fa {
        display: none;
      }

      @media screen and (max-width: 48rem) {
        i.fa {
          display: block;
        }

        span {
          display: none;
        }
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        border: 0 solid $primary;
        border-width: 20px 10px;
        width: 0;
        height: 0;
      }
      &:before {
        left: -20px;
        border-left-color: transparent;
      }
      &:after {
        left: 100%;
        border-color: transparent;
        border-left-color: $primary;
      }

      &:active {
        background-color: $green-darken;

        &:before {
          border-color: $green-darken;
          border-left-color: transparent;
        }
        &:after {
          border-left-color: $green-darken;
        }
      }
    }
  }
}
