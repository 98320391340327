@import "./config";
@import "./mixins";
@import "./utilities";

.progress-loader {
  width: 100%;
  height: 0.25rem;
  position: fixed;
  top: 0;
  left: 0;

  &-inner {
    height: 100%;
    background-color: $primary;
    @include transition(width 0.5s ease);
  }
}
