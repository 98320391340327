@import "./config";
@import "./mixins";
@import "./utilities";

@-webkit-keyframes carousel-slide {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}
@keyframes carousel-slide {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}

@-webkit-keyframes rotating {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(370deg);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(370deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.2);
  }
  80%,
  100% {
    transform: scale(0);
  }
}

@keyframes spin {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.2);
  }
  80%,
  100% {
    transform: scale(0);
  }
}

@-webkit-keyframes pumping {
  0% {
    @include transform(scale(0.95));
  }
  50% {
    @include transform(scale(1));
  }
  100% {
    @include transform(scale(0.95));
  }
}

@keyframes pumping {
  0% {
    @include transform(scale(0.95));
  }
  50% {
    @include transform(scale(1));
  }
  100% {
    @include transform(scale(0.95));
  }
}

@-webkit-keyframes zoom-in {
  0% {
    opacity: 0;
    @include transform(scale(0.8));
  }

  100% {
    opacity: 1;
    @include transform(scale(1));
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    @include transform(scale(0.8));
  }

  100% {
    opacity: 1;
    @include transform(scale(1));
  }
}

@-webkit-keyframes tick {
  0% {
    @include transform(scale(0));
  }

  90% {
    @include transform(scale(1.3));
  }

  100% {
    @include transform(scale(1));
  }
}

@keyframes tick {
  0% {
    @include transform(scale(0));
  }

  90% {
    @include transform(scale(1.3));
  }

  100% {
    @include transform(scale(1));
  }
}

@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    @include transform(translateX(-100%));
  }

  100% {
    opacity: 1;
    @include transform(translateX(0%));
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    @include transform(translateX(-100%));
  }

  100% {
    opacity: 1;
    @include transform(translateX(0%));
  }
}

@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
    @include transform(translateX(100%));
  }

  100% {
    opacity: 1;
    @include transform(translateX(0%));
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    @include transform(translateX(100%));
  }

  100% {
    opacity: 1;
    @include transform(translateX(0%));
  }
}

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    @include transform(translateY(-100%));
  }

  100% {
    opacity: 1;
    @include transform(translateY(0%));
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    @include transform(translateY(-100%));
  }

  100% {
    opacity: 1;
    @include transform(translateY(0%));
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    @include transform(translateY(100%));
  }

  100% {
    opacity: 1;
    @include transform(translateY(0%));
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    @include transform(translateY(100%));
  }

  100% {
    opacity: 1;
    @include transform(translateY(0%));
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes move-text-color {
  0% {
    bottom: -0.2em;
    opacity: 1;
    color: #3e5c76;
  }

  50% {
    bottom: 0.2em;
    color: #ec058e;
  }

  100% {
    bottom: 0;
    opacity: 1;
    color: #cb3126;
  }
}

@keyframes move-text-color {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinkCursor {
  0%,
  75% {
    opacity: 1;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@keyframes blinkCursor {
  0%,
  75% {
    opacity: 1;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes toggleVisibility {
  0%,
  75% {
    visibility: visible;
  }

  76%,
  100% {
    visibility: hidden;
  }
}

@keyframes toggleVisibility {
  0%,
  75% {
    visibility: visible;
  }

  76%,
  100% {
    visibility: hidden;
  }
}

@-webkit-keyframes animate-timeline {
  0% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes animate-timeline {
  0% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes expand-menu-animation {
  from {
    width: 0;
  }
  to {
    width: 20rem;
  }
}

@keyframes expand-menu-animation {
  from {
    width: 0;
  }
  to {
    width: 20rem;
  }
}

.blink-cursor {
  @include animation(blinkCursor 1s steps(3) infinite);
}

.toggle-visibility {
  @include animation(toggleVisibility 1s ease);
}

.zoom-in {
  @include animation(zoom-in 0.5s ease);
}

.slide-left {
  @include animation(slide-left 0.5s ease);
}

.slide-right {
  @include animation(slide-right 0.5s ease);
}

.slide-up {
  @include animation(slide-up 0.5s ease);
}

.slide-down {
  @include animation(slide-down 0.5s ease);
}

.pumping {
  @include animation(pumping 1s linear infinite);
}

.fade-in {
  @include animation(fade-in 0.5s ease);
}

.fade-out {
  @include animation(fade-out 2s ease);
}
