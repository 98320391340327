.select-country-container {
  height: 100vh;
  width: 100vw;
  background-color: $secondary;

  @include flexCenter(row, center);

  &-inner {
    width: 80%;
    height: 23.5rem;
    background-color: $white;
    border-radius: 0.375rem;
    padding: 4rem 2rem;

    @include flexCenter(row, center);

    .left-pane {
      flex: 1;
      max-width: 40%;
      margin-right: 2rem;
      text-align: left;

      h2 {
        font-weight: get-weight(medium);
        font-size: 2rem;
      }

      p {
        font-size: 1.1rem;
        font-weight: get-weight(regular);
      }
    }

    .right-pane {
      flex: 2;
      min-width: 60%;
      height: auto;

      .country-item {
        background-color: $dark;
        border-radius: 0.375rem;
        height: 100%;
        padding: 1rem;
        margin-right: 1rem;

        @include flexCenter(column, center, flex-start);

        &-top {
          flex: 1;
          width: 100%;
          @include flexCenter(column, center);

          img {
            width: 4.5rem;
            height: 3.375rem;
            object-fit: contain;
          }

          p.label {
            color: $white;
            margin: 0.5rem 0 0 0;
            font-size: 0.75rem;
            text-transform: uppercase;
            font-weight: get-weight(regular);
          }
        }

        &-bottom {
          margin-top: 1rem;
          width: 100%;

          &-inner {
            @include flexCenter(row, space-between);

            &-left {
              h3 {
                color: $white;
                font-size: 1.2rem;
                font-weight: get-weight(medium);
              }
            }

            &-right {
              button {
                background-color: $white;
                color: $dark;
                border: 1px solid $white;
                font-size: 0.875rem;
                text-align: center;
                padding: 0.25rem 1rem;
                border-radius: 0.175rem;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 48rem) {
      height: auto;
      max-height: 90%;

      @include flexCenter(column, center);

      .left-pane {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        text-align: center;
      }

      .right-pane {
        min-width: auto;
        width: 100%;

        .country-item {
          margin-right: 0.15rem;
        }
      }
    }
  }
}
